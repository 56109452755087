export enum HelperEnum {
  PAD_WORDS = 8, // Number of words to pad on either side of the selected term.
}

// debounce timeouts in milliseconds
export enum DebounceEnum {
  FILTER_CALLS = 400,
}

// Data Synopsis Column Type
export enum ColumnType {
  TEXT = 'text',
  DATE = 'date',
  NUMBER = 'number',
  TAGS = 'tags',
}

// Column type for input columns stored in survey config
export enum InputColumnType {
  TEXT = 'text',
  DATE = 'date',
  NUMBER = 'number',
  TAGS = 'tags',
}

export enum CommentTreatment {
  SAME = 'same_themes',
  DIFFERENT = 'different_themes',
  CONCATENATE = 'concatenate',
}

export enum DateResolution {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
}

export enum ScoreType {
  NPS = 'nps',
  AVERAGE = 'average',
  THRESHOLD = 'threshold',
  SENTIMENT = 'sentiment',
}

export enum FilterType {
  CATEGORIES = 'categories',
  DATE = 'date',
  FREETEXT = 'freetext',
  HIERARCHICAL = 'hierarchical',
  SAVED_FILTERS = 'saved-filters',
  SENTIMENT = 'sentiment',
  TAGS = 'tags',
  THEMES = 'themes',
}

export enum DataSourceIntegrationStatus {
  ENABLED = 'ENABLED',
  STOPPED = 'STOPPED',
}

export enum AutoCutType {
  LIST = 'list',
  DATE = 'date',
  SCORE = 'score',
}

export enum AutoCutSortType {
  ALPHABETICAL = 'alphabetical',
}

export enum CaseHandling {
  TITLECASE = 'titlecase',
  LOWERCASE = 'lowercase',
  UPPERCASE = 'uppercase',
}

export enum SurveyDataStatus {
  NODATA = 'NODATA', // no data has been uploaded
  NORESULTS = 'NORESULTS', // data has been uploaded but there are no results available to view
  PROCESSING = 'PROCESSING', // data has been uploaded, no results available but there is some job currently in progress
  REVIEWING = 'REVIEWING', // data is ready, but thematic staff are reviewing the results
  READY = 'READY', // normal status
}

export enum SurveyStatus {
  UNKNOWN = 'unknown',
  NORMAL = 'normal',
  DELETED = 'deleted',
  NEEDS_REVIEW = 'needs_review',
  LIMITED = 'limited',
  ONE_OFF = 'one_off',
  UPLOAD_ONLY = 'upload_only',
}

export enum MetricsResolution {
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
}

export enum LocalStorageKeys {
  HAS_VIEWED_ANALYSIS = 'has_viewed_analysis',
}

export enum OrganizationType {
  NORMAL = 'Type 1',
}

export enum SelectionWidgetType {
  SELECTION_DROPDOWN = 'SELECTION_DROPDOWN',
  SELECTION_SCORERANK = 'SELECTION_SCORERANK',
  SELECTION_SCORE_BREAKDOWN = 'SELECTION_SCORE_BREAKDOWN',
}

export enum DashboardWidgetType {
  EMPTY = 'EMPTY',
  COMMENTS = 'COMMENTS',
  KEY_TAKEAWAYS = 'KEY_TAKEAWAYS',
  METADATA = 'METADATA',
  THEMES1 = 'THEMES1',
  THEMES_NEW = 'THEMES_NEW',
  SIGNIFICANT_CHANGES = 'SIGNIFICANT_CHANGES',
  SIGNIFICANT_SCORE_CHANGES = 'SIGNIFICANT_SCORE_CHANGES',
  THEMES_CHANGES = 'THEMES_CHANGES',
  THEMES_COMPARE_TWO = 'THEMES_COMPARE_TWO',
  THEMES_SIMPLE = 'THEMES_SIMPLE',
  IMPACT_SIMPLE = 'IMPACT_SIMPLE',
  IMPACT_CHANGES = 'IMPACT_CHANGES',
  SCORE_CHANGES = 'SCORE_CHANGES',
  SCORE_OVERTIME = 'SCORE_OVERTIME',
  SCORE = 'SCORE',
  SCORE_DETAIL = 'SCORE_DETAIL',
  SUMMARY = 'SUMMARY',
}

export enum DashboardDateResolution {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  WEEKLY = 'weekly',
}
export enum WidgetSortValue {
  ASC = 'asc',
  DESC = 'desc',
  ASC_ABS = 'asc_abs',
  DESC_ABS = 'desc_abs',
}

export enum SignificantChangeType {
  EMERGING = 'emerging',
  VOLUME = 'volume',
  SCORE = 'score',
}

export enum WidgetOrderingValue {
  BASELINE = 'baseline',
  COMPARISON = 'comparison',
  DIFFERENCE = 'difference',
}

export enum WidgetThemeLevel {
  BASETHEME = 'basetheme',
  SUBTHEME = 'subtheme',
}

export enum WidgetSummaryType {
  ALL = 'all',
  ISSUE = 'issue',
  REQUEST = 'request',
  QUESTION = 'question',
  NEGATIVE = 'negative',
  POSITIVE = 'positive'
}

export enum IntegrationType {
  UNKNOWN = 'unknown',
  AIRCALL = 'aircall',
  ASKNICELY = 'asknicely',
  CONFIRMIT = 'confirmit',
  DELIGHTED = 'delighted',
  DISCORD = 'discord',
  FEEFO = 'feefo',
  GONG = 'gong',
  GOOGLEPLAY = 'googlePlay',
  GOOGLEBIGQUERY = 'google_bigquery',
  GOOGLESHEETS = 'google_sheets',
  INTERCOM = 'intercom',
  JOTFORM = 'jotform',
  QUALTRICS = 'qualtrics',
  QUESTIONPRO = 'questionpro',
  REEVOO = 'reevoo',
  SALESFORCE = 'salesforce',
  SLACK = 'slack',
  SNOWFLAKEREADER = 'snowflake_reader',
  SURVEYGIZMO = 'survey_gizmo',
  SURVEYMONKEY = 'survey_monkey',
  SURVICATE = 'survicate',
  TRUSTPILOT = 'trustpilot',
  TYPEFORM = 'typeform',
  YABBLE = 'yabble',
  YOTPO = 'yotpo',
  ZENDESK = 'zendesk',

  // The following are data source types that don't have an integration
  APPSTORE = 'appstore',
  DISCOURSE = 'discourse',
  REDDIT = 'reddit',
  TWITTER = 'twitter',
}

export enum IntegrationStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export enum UploadType {
  INITIALDATA = 'ProcessingJobType.initialdata',
  NEWDATA = 'ProcessingJobType.newdata',
  REPLACEDATA = 'ProcessingJobType.replacedata',
  REPLACEINPUTDATA = 'ProcessingJobType.replaceinputdata',
  NEWTHEMES = 'ProcessingJobType.newthemes',
  NEWCONCEPTS = 'ProcessingJobType.newconcepts',
  NEWPARAMETERS = 'ProcessingJobType.newparameters',
  DELETEROWS = 'ProcessingJobType.deleterows',
  ADDCOLUMN = 'ProcessingJobType.addcolumn',
  DROPCOLUMN = 'ProcessingJobType.dropcolumn',
  MAPCOLUMN = 'ProcessingJobType.mapcolumn',
  RECONFIGURE = 'ProcessingJobType.reconfigure',
}

export enum UploadStatus {
  QUEUED = 'queued',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  ERRORED = 'errored',
  CANCELED = 'canceled',
}

export enum DataSourceIntegrationRunStatus {
  QUEUED = 'queued',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  ERRORED = 'errored',
  CANCELED = 'canceled',
}

export enum WorkflowActionType {
  EMAIL = 'email',
  SLACK = 'slack',
}