import Auth from 'Auth/Auth';
import { CommentColumn, FilterConfiguration } from 'api/interfaces';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import { Fetchable } from 'lib/fetch';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import queryBuilder from 'vue/libs/queryBuilder';

const AbortReason = {
  RequestCanceled: 'requestCanceled'
} as const;

type EntryDB = {
  entries: object,
  pending: object,
  controllers: {
    [key: string]: {
      controller: AbortController,
      id?: string
    }
  } | object,
};

function createEntryDB(entries?: object): EntryDB {
  if (entries === undefined) {
    entries = {};
  }
  return { entries, pending: {}, controllers: {} };
}

type Options = { [k: string]: unknown };

export type UrlOptions<Opt> = {
  basetheme?: string,
  cluster?: boolean,
  commentColumns?: CommentColumn[] | null,
  dashboardId?: string,
  dimension?: string,
  filter?: string,
  focusTheme?: string
  options?: Opt,
  page?: number,
  pageSize?: number,
  segmentSelection?: string,
  sentiment?: string,
  subtheme?: string,
  summarize?: boolean,
  summarizeType?: string,
  summarizer?: string,
  summaryContext?: string
  textType?: string
  type?: string,
  version?: string
};

function getUrl<Opt extends Options>(
  path: string,
  {
    basetheme,
    commentColumns,
    dashboardId,
    dimension,
    filter,
    focusTheme,
    options,
    page,
    pageSize,
    sentiment,
    subtheme,
    summarizeType,
    summarizer
  }: UrlOptions<Opt>
) {
  const tokens = [_.get(options, 'dataSource', ''), `${ path }`];
  // construct query
  const queryTokens: string[] = [];

  let filterLocal = filter;
  if (options?.baseFilter) {
    filterLocal = queryBuilder.appendToFilter(filterLocal as string, options?.baseFilter as string);
  }

  // append the filter query (if exists)
  if (filterLocal) {
    queryTokens.push(`filter=${ encodeURIComponent(filterLocal) }`);
  }

  if (!_.isEmpty(commentColumns)) {
    /* @ts-expect-error */
    queryTokens.push(`columns=${ commentColumns.join(',') }`);
  }
  if (dashboardId) {
    queryTokens.push(`forReport=${ dashboardId }`);
  }
  if (summarizeType) {
    queryTokens.push(`summarize=${ summarizeType }`);
  }
  if (summarizer) {
    queryTokens.push(`summarizer=${ summarizer }`);
  }

  if (options) {
    const o = encodeURIComponent(JSON.stringify(options));
    queryTokens.push(`options=${ o }`);
    if (options.comparisonFilter) {

      let comparisonFilterLocal = options.comparisonFilter as string;
      if (options.baseFilter) {
        comparisonFilterLocal = queryBuilder.appendToFilter(comparisonFilterLocal, options.baseFilter as string);
      }
      queryTokens.push(`comparisonFilter=${ encodeURIComponent(comparisonFilterLocal) }`);
    }
  }
  if (dimension) {
    queryTokens.push(`dimension=${ dimension }`);
  }
  if (page) {
    queryTokens.push(`page=${ page }`);
  }
  if (pageSize) {
    queryTokens.push(`pageSize=${ pageSize }`);
  }
  if (sentiment) {
    queryTokens.push(`sentiment=${ sentiment }`);
  }
  if (basetheme) {
    queryTokens.push(`basetheme=${ encodeURIComponent(basetheme) }`);
  }
  if (subtheme) {
    queryTokens.push(`subtheme=${ encodeURIComponent(subtheme) }`);
  }
  if (focusTheme) {
    queryTokens.push(`focusTheme=${ encodeURIComponent(focusTheme) }`);
  }
  // create final url
  let finalUrl = tokens.join('');
  if (!_.isEmpty(queryTokens)) {
    finalUrl = finalUrl + '?' + queryTokens.join('&');
  }
  return finalUrl;
}

type PostData = {
  config: object,
  context: object
};

async function getEntry(
  entryDB: EntryDB,
  url: string,
  postData?: PostData | null,
  cachedOnly?: boolean,
  location?: string
) {
  // no url means no data
  if (_.isEmpty(url)) {
    return {};
  }
  let key = url;
  if (postData) {
    key = key + JSON.stringify(postData);
  }
  if (_.hasIn(entryDB.entries, key)) {
    // entries are supposed to be immutable
    return _.cloneDeep(entryDB.entries[key]);
  }
  // if there's already a pending await chain, return that one
  if (entryDB.pending[key]) {
    return entryDB.pending[key];
  }

  // if we should only return cached entries, we should fail here
  if (cachedOnly) {
    return null;
  }

  const controller = new AbortController();
  try {
    const urlObject = new URL(url);

    // add a cache buster
    urlObject.searchParams.set('buster', Math.round(new Date().getTime() / 1000).toString());

    url = urlObject.toString();

    const authConfig = postData ? {
      method: 'POST',
      body: JSON.stringify(postData),
      signal: controller.signal,
      isRaw: true,
    } : {
      method: 'GET',
      signal: controller.signal,
      isRaw: true,
    };

    const promise = Auth
      .fetch(url, authConfig)
      .then((result: Fetchable<unknown>) => {

        if (!result.ok) {
          return Promise.reject(result);
        }

        entryDB.entries[key] = result.data;
        return _.cloneDeep(result.data);
      });

    entryDB.pending[key] = promise;

    entryDB.controllers[key] = {
      controller: controller,
      id: location
    };

    return await promise;

  } catch (e) {
    if (controller.signal.reason === AbortReason.RequestCanceled) {
      return null;
    }

    if (window.rg4js) {
      window.rg4js('send', {
        error: e,
        customData: { url }
      });
    }

    throw e;
  } finally {
    entryDB.pending[key] = undefined;
    entryDB.controllers[key] = undefined;
  }
}

export default {
  getUrl: getUrl,
  configDB: createEntryDB(),
  getConfig: async function <Opt extends Options>(
    options: Opt,
    dashboardId: string
  ) {
    return getEntry(this.configDB, getUrl('config', { options, dashboardId }));
  },
  filtersDB: createEntryDB(),
  getPreparedFilters: async function <Opt extends Options>(
    filter: string,
    filterData: FilterConfiguration[],
    options: Opt,
    dashboardId: string
  ) {
    return getEntry(
      this.filtersDB,
      getUrl('createFilters', { filter, options, dashboardId }),
      /* @ts-expect-error */
      filterData
    );
  },
  themeHierarchyDB: createEntryDB(),
  getThemeHierarchy: async function <Opt extends Options>(
    commentColumns: CommentColumn[],
    options: Opt
  ) {
    return getEntry(
      this.themeHierarchyDB,
      getUrl('themesHierarchy', {
        commentColumns,
        options
      })
    );
  },
  themesDB: createEntryDB(),
  getThemes: async function <Opt extends Options>(
    filter: string,
    commentColumns: CommentColumn[],
    options: Opt
  ) {
    return getEntry(
      this.themesDB,
      getUrl('themes', {
        filter,
        commentColumns,
        options
      })
    );
  },
  countsDB: createEntryDB(),
  getCounts: async function <Opt extends Options>(
    filter: string,
    commentColumns: CommentColumn[],
    options: Opt
  ) {
    return getEntry(
      this.countsDB,
      getUrl('counts', {
        filter,
        commentColumns,
        options
      })
    );
  },
  statisticsDB: createEntryDB(),
  getStatistics: async function <Opt extends Options>(
    filter: string,
    commentColumns: CommentColumn[],
    options: Opt,
    dimension: string
  ) {
    return getEntry(
      this.statisticsDB,
      getUrl('statistics', {
        filter,
        commentColumns,
        options,
        dimension
      })
    );
  },
  // report widget cache
  widgetsDB: createEntryDB(),
  getWidget: async function (
    url: string,
    config: PostData['config'],
    context: PostData['context']
  ) {
    return getEntry(this.widgetsDB, url, { config, context });
  },
  // report comments cache
  widgetsCommentsDB: createEntryDB(),
  getWidgetComments: async function (url: string) {
    return getEntry(this.widgetsCommentsDB, url);
  },
  commentsDB: createEntryDB(),
  clearCommentsCache: function () {
    this.commentsDB = createEntryDB();
  },
  getComments: async function <Opt extends Options>(
    filter: string,
    commentColumns: CommentColumn[] | null,
    options: Opt,
    pagination: { page: number, pageSize: number }
  ) {
    const { page, pageSize } = pagination;

    return getEntry(
      this.commentsDB,
      getUrl<Opt>('commentsV2', {
        filter,
        commentColumns,
        options,
        page,
        pageSize,
        sentiment: 'all',
      })
    );
  },
  themesByDate: createEntryDB(),
  getThemesByDate: async function <Opt extends { dateResolution?: string }>(
    filter: string,
    commentColumns: CommentColumn[],
    options: Opt
  ) {
    options = cloneDeep(options); // we're mutating it below

    try {
      const data = await getEntry(
        this.themesByDate,
        getUrl('themesByDate', {
          filter,
          commentColumns,
          options
        })
      );
      // populate the themes database with what we know
      _.forEach(data.dateQueries, (dateQuery, i) => {
        const filterWithDate = queryBuilder.appendToFilter(filter, dateQuery);
        delete options.dateResolution;
        const url = getUrl('themes', {
          filter: filterWithDate,
          commentColumns,
          options
        });
        this.themesDB.entries[url] = data.themes[i];
      });
      return data;
    } catch (e) {
      return e;
    }
  },
  results: createEntryDB(),
  getResults: async function <Opt extends Options>(
    filter: string,
    commentColumns: CommentColumn[],
    options: Opt
  ) {
    return getEntry(
      this.commentsDB,
      getUrl('results', {
        filter,
        commentColumns,
        options
      })
    );
  },
  scoreDB: createEntryDB(),
  getScore: async function <Opt extends Options>(
    filter: string,
    commentColumns: CommentColumn[],
    options: Opt
  ) {
    return getEntry(
      this.scoreDB,
      getUrl('score', {
        filter,
        commentColumns,
        options
      })
    );
  },
  scoreByDate: createEntryDB(),
  getScoreByDate: async function <Opt extends Options>(
    filter: string,
    commentColumns: CommentColumn[],
    options: Opt
  ) {

    try {
      const data = await getEntry(
        this.scoreByDate,
        getUrl('scoreByDate', {
          filter,
          commentColumns,
          options
        })
      );
      // populate the themes database with what we know
      _.forEach(data.dateQueries, (dateQuery, i) => {
        const filterWithDate = queryBuilder.appendToFilter(filter, dateQuery);
        const url = getUrl('score', {
          filter: filterWithDate,
          options
        });
        this.scoreDB.entries[url] = data.scores[i];
      });
      return data;
    } catch (e) {
      return e;
    }
  },

  periodComparisons: createEntryDB(),
  getComparePeriods: async function <Opt extends Options>(
    filter: string,
    previousPeriod: string,
    nextPeriod: string,
    commentColumns: CommentColumn[],
    options: Opt
  ) {
    const urlComponents = ['comparePeriods', encodeURIComponent(previousPeriod), encodeURIComponent(nextPeriod)];
    const url = urlComponents.join('/');
    try {
      return getEntry(
        this.periodComparisons,
        getUrl(url, {
          filter,
          commentColumns,
          options
        })
      );
    } catch (e) {
      return e;
    }
  },

  themeTrends: createEntryDB(),
  getThemeTrends: async function <Opt extends Options>(
    filter: string,
    endDate: string,
    commentColumns: CommentColumn[],
    options: Opt
  ) {
    const urlComponents = ['themeTrends', encodeURIComponent(endDate)];
    const url = urlComponents.join('/');
    try {
      return getEntry(
        this.themeTrends,
        getUrl(url, {
          filter,
          commentColumns,
          options
        })
      );
    } catch (e) {
      return e;
    }
  },
  summarizations: createEntryDB(),
  getSummarization: async function <Opt extends Options>(
    filter: string,
    basetheme: string | undefined,
    subtheme: string | undefined,
    sentiment: string, // aka volumeType
    options: UrlOptions<Opt>,
    cachedOnly: boolean,
    location?: string
  ) {
    if (!FeatureFlagManager.checkFlag(FlagKeys.USE_CLAUDE_SUMMARIZER)) {
      options.summarizer = "claude";
    }
    try {
      return getEntry(
        this.summarizations,
        getUrl('summarization', {
          filter,
          options,
          sentiment,
          basetheme,
          subtheme
        }),
        null,
        cachedOnly,
        location
      );
    } catch (e) {
      return e;
    }
  },
  clearSummarizationCache: function () {
    this.summarizations = createEntryDB();
  },
  cancelSummarizationRequestsById: function (id: string) {
    Object
      .values(this.summarizations.controllers)
      .forEach((value: { controller: AbortController, id?: string }) => {
        if (value && value.id && value.id === id && value.controller) {
          value.controller.abort(AbortReason.RequestCanceled);
        }
      });
  },
  themeSummarizations: createEntryDB(),
  getThemeSummarization: async function <Opt extends Options>(
    filter: string,
    summarizeType: string,
    options: UrlOptions<Opt>,
  ) {
    if (!FeatureFlagManager.checkFlag(FlagKeys.USE_CLAUDE_SUMMARIZER)) {
      options.summarizer = "claude";
    }
    try {
      return getEntry(
        this.themeSummarizations,
        getUrl('themes/summarization', {
          filter,
          options,
          summarizeType,
        }),
        null
      );
    } catch (e) {
      return e;
    }
  },
  clearThemeSummarizationCache: function () {
    this.themeSummarizations = createEntryDB();
  },
  resetAllDBs() {
    Object.values(this)
      .filter(val => 'entries' in val)
      .forEach((db: EntryDB) => {
        db.entries = {};
        db.pending = {};
        db.controllers = {};
      });
  }
};
