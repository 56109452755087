<template>
  <div
    class="explore-tool explore-tool-grid"
  >
    <explore-tool-summary
      v-if="canSeeSummarization"
      :survey-title="title"
      :survey-id="surveyId"
      :org-id="organization"
      :has-comparison="hasComparison"
      :request-options="requestOptions"
    />
    <link-to-answer
      v-if="canAccessAnswer && canSeeAnswerBanners && !answersBannerLocallyDismissed && !isAnswersBannerDismissed"
      :org-id="organization"
      :survey-id="surveyId"
      :sources="sources"
      :inline="true"
      :location="AnswersBannerLocations.Analysis"
      :on-dismiss="dismissBanner"
    />
    <div
      :class="{ 'expanded': expanded }"
      class="explore-tool__main"
    >
      <explore-tool-header
        :has-comparison="hasComparison"
        :has-impact-comparison="hasImpactComparison"
        :themes="themes"
      />
      <div class="explore-tool__selection-details">
        <div
          :class="{
            'explore-tool__counts': true,
            'explore-tool__counts--loading': loading
          }"
        >
          <label class="baseline">
            <span />
            <explore-tool-selection-detail
              class="count-label"
              :selected-score="selectedScore"
              :score="baseScore"
              :scored-responses="baseRatings"
              :counts="baselineCounts"
              :error-message="baselineErrorMessage"
              :are-comments-loading="loading"
            />
          </label>
          <label
            v-if="hasComparison"
            class="comparison"
          >
            <span />
            <explore-tool-selection-detail
              class="count-label"
              :selected-score="selectedScore"
              :score="compScore"
              :scored-responses="compRatings"
              :counts="comparisonCounts"
              :error-message="comparisonErrorMessage"
              :are-comments-loading="loading"
            />
          </label>
        </div>

        <el-button
          v-if="analysisConfigStore.canManageThemes"
          class="ob-edit-themes edit-themes"
          type="accent"
          size="small"
          @click="goToEditThemes"
        >
          Manage themes
        </el-button>
      </div>
      <div class="explore-tool__chart">
        <div
          v-if="loading"
          class="explore-tool__chart__loading"
        >
          <div v-loading="true" />
          <h2>Fetching data...</h2>
        </div>
        <explore-chart
          v-if="!loading"
          :themes="themes"
          :has-comparison="hasComparison"
          :has-impact-comparison="hasImpactComparison"
          :base-total="baselineCount"
          :comp-total="comparisonCount"
          :show-max-themes="expanded ? undefined : 10"
        />
      </div>
      <div class="explore-tool__expander">
        <el-button
          :title="expanded ? 'Collapse chart' : 'Expand chart'"
          @click="expanded = !expanded"
        >
          <span
            v-if="expanded"
            key="double-up-icon"
          >
            Show top themes
          </span>
          <span
            v-else
            key="double-down-icon"
          >
            Show all themes
          </span>
        </el-button>
      </div>
    </div>
    <explore-detail
      :has-comparison="hasComparison"
      :baseline-title="baselineTitle"
      :comparison-title="comparisonTitle"
      :selected-score-external="selectedScore"
      :is-loading-themes="loading"
      :date-label="commentsExtraFilter ? commentsExtraFilter.subTitle : undefined"
      class="explore-tool__detail"
      @onSelectionChanged="onSelectionChanged"
    />
  </div>
</template>

<script>
import { find, get, pick, sum, template, throttle } from 'lodash';

import ExploreDetail from 'vue/explore-detail/ExploreDetail.vue';
import ExploreChart from 'vue/explore/ExploreChart.vue';
import ExploreToolHeader from 'vue/explore/ExploreToolHeader.vue';
import ExploreToolSummary from 'vue/explore/ExploreToolSummary.vue';
import ExploreToolSelectionDetail from 'vue/explore/ExploreToolSelectionDetail.vue';
import themeHelpers from 'vue/libs/themeHelpers';
import { AnswersBannerLocations, LinkToAnswer } from 'components/Banners/LinkToAnswer';
import { ReactInVue } from 'vuera';

import toFixed from 'vue/libs/to-fixed';

import {
  getAnalysisConfigStore,
  getAnalysisToolsUIStore,
  getFilterStore,
  getInitConfigStore,
  getExploreToolUIStore,
  getAnswersStore,
  getUserStore
} from 'stores/RootStore';
import dataMixin from 'vue/tools/Utils/dataMixin';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import { DismissibleElements } from 'stores/UserStore';

export default {
  name: 'ExploreTool',
  components: {
    ExploreChart,
    ExploreToolHeader,
    ExploreDetail,
    ExploreToolSelectionDetail,
    ExploreToolSummary,
    LinkToAnswer: ReactInVue(LinkToAnswer),
  },
  mixins: [dataMixin],
  data() {
    return {
      commentsExtraFilter: undefined,
      expanded: false,
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      analysisConfigStore: getAnalysisConfigStore(),
      filterStore: getFilterStore(),
      initConfigStore: getInitConfigStore(),
      exploreToolUIStore: getExploreToolUIStore(),
      answersStore: getAnswersStore(),
      userStore: getUserStore(),
      AnswersBannerLocations,
      answersBannerLocallyDismissed: false,
    };
  },
  computed: {
    canSeeSummarization() {
      return FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_DATASET_SUMMARIZATION);
    },
    title() {
      return this.initConfigStore.title;
    },
    axisType() {
      return this.exploreToolUIStore.axisType;
    },
    exploreAxes() {
      return this.exploreToolUIStore.exploreAxes;
    },
    requestOptions() {
      return this.exploreToolUIStore.requestOptions;
    },
    searchFilter() {
      return this.exploreToolUIStore.searchFilter;
    },
    selectedScore() {
      return this.exploreToolUIStore.selectedScore;
    },
    themes() {
      return this.exploreToolUIStore.themes;
    },
    scoreFormatter() {
      return this.exploreToolUIStore.scoreFormatter;
    },
    organization() {
      return this.initConfigStore.organization;
    },
    surveyId() {
      return this.initConfigStore.surveyId;
    },
    visId() {
      return this.initConfigStore.visId;
    },
    viewId() {
      return this.initConfigStore.viewId;
    },
    sources() {
      return [{
        view: this.viewId,
        visualization: this.visId,
        survey: this.surveyId
      }];
    },
    selections() {
      return this.filterStore.selections;
    },
    baseRatings() {
      const hist = get(this, 'baselineScores.hist', []);
      return sum(hist);
    },
    baseScore() {
      const { scoreFormatter } = this;
      const score = get(this, 'baselineScores.score', 0);

      return template(scoreFormatter)({ value: toFixed(score, 1) });
    },
    compRatings() {
      const hist = get(this, 'comparisonScores.hist', []);
      return sum(hist);
    },
    compScore() {
      const { scoreFormatter } = this;
      const score = get(this, 'comparisonScores.score', 0);

      return template(scoreFormatter)({ value: toFixed(score, 1) });
    },
    editThemesLink() {
      const { organization, surveyId } = this;
      return `/#/c/${organization}/s/${surveyId}/themes`;
    },
    hasVisibleTheme() {
      const { selectedTheme, themes } = this;
      if (selectedTheme) {
        const theme = find(themes, { title: selectedTheme.name });
        return theme && theme.matches;
      }
      return false;
    },
    range() {
      return get(this, 'requestOptions.scoreOptions.range');
    },
    scoreType() {
      return get(this, 'requestOptions.scoreType');
    },
    commentsSelections() {
      // IF there is a more specific version of comments selections, pass that along
      if (this.commentsExtraFilter) {
        return this.commentsExtraFilter;
      }
      // otherwise it is the one we have generated ourselves
      const commentsSelection = {
        selections: {
          baseline: {
            title: this.selections.baseline.title,
            query: this.selections.baseline.query,
            count: get(this.selectedCounts, 'baseCount'),
            sentimentCounts: get(this.selectedCounts, 'baseSentimentCounts')
          },
          comparison: {
            title: this.selections.comparison.title,
            query: this.selections.comparison.query,
            count: get(this.selectedCounts, 'compCount'),
            sentimentCounts: get(this.selectedCounts, 'compSentimentCounts')
          }
        }
      };
      return commentsSelection;
    },
    canAccessAnswer() {
      return this.answersStore.userHasAccessToAnswers;
    },
    canSeeAnswerBanners() {
      return FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_ANSWER_BANNERS);
    },
    isAnswersBannerDismissed() {
      return this.userStore.isDismissed(DismissibleElements.AnswersAnalysisBanner);
    },
  },
  watch: {
    $route: {
      handler(to) {
        if (!to) {
          return;
        }
        const { expand } = to.query;
        if (expand === 'true') {
          this.expanded = true;
        }
      },
      immediate: true
    },
    requestOptions() {
      this.onFilterChange();
    },
    baselineData() {
      const themeData = pick(
        this,
        'baselineCount',
        'baselineThemes',
        'baselineScores',
        'comparisonCount',
        'comparisonThemes',
        'comparisonScores'
      );

      this.exploreToolUIStore.setThemeData(themeData);
    },
    selections: {
      handler() {
        this.resetOverTimeChartSelection();
        this.onFilterChange();
      },
      immediate: true
    },
    searchFilter() {
      this.ensureThemeSelected();
    },
    themes() {
      this.ensureThemeSelected();
    },
    commentsSelections() {
      if (this.selectedTheme !== null) {
        this.analysisToolsUIStore.setCommentsSelection(this.commentsSelections);
      }
    }
  },
  mounted() {
    this.ensureThemeSelected = throttle(this.ensureThemeSelected, 50);
  },
  methods: {
    resetOverTimeChartSelection() {
      this.commentsExtraFilter = undefined;
    },
    ensureThemeSelected() {
      const { $route, hasVisibleTheme, themes } = this;
      if (!hasVisibleTheme && themes && themes.length) {
        // The precendence for selecting a theme is
        // - does it match the theme search
        // - has it been selected in the URL
        // - first available

        const themeFromSearch = themeHelpers.searchThemes(themes, this.searchFilter);
        const foundThemeName = themeFromSearch ? themeFromSearch.title : null;
        const theme = foundThemeName || $route.query.theme || (find(themes, { matches: true }) || {}).title || null;
        const subtheme = $route.query.subtheme || null;

        this.analysisToolsUIStore.setThemeState({ theme, subtheme });
      }
    },
    goToEditThemes() {
      window.location = this.editThemesLink;
    },
    onSelectionChanged(selection) {
      if (selection) {
        this.commentsExtraFilter = selection;
      } else {
        this.commentsExtraFilter = undefined;
      }
    },
    dismissBanner() {
      this.answersBannerLocallyDismissed = true;
      this.userStore.dismissElement(DismissibleElements.AnswersAnalysisBanner);
    },
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/element-variables.scss';
@import '../components/Charts/volume-chart-variables.scss';

.explore-tool {
  margin: 0px 0 20px;
  text-align: left;
  &__main {
    box-shadow: 0 2px 6px 0 rgba(84, 99, 119, 0.3);
    border-radius: 4px;
    display: grid;
    padding: 0;
    grid-template-rows: 40px 40px $--volume-chart-height 46px;
    grid-template-columns: 1fr;
    grid-template-areas:
      'header'
      'counts'
      'body'
      'expander';
    &.expanded {
      grid-template-rows: auto auto auto 46px;
    }
  }

  &__selection-details {
    border-bottom: 2px solid $--border-color;
    grid-area: counts;
    display: flex;
    padding: 5px 10px;
    align-content: center;
    justify-content: space-between;
    height: 40px;
    .edit-themes {
      margin: auto 0;
    }
  }
  &__counts {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;

    > label {
      align-items: center;
      display: grid;
      grid-gap: 0 5px;
      grid-template-areas:
        'spot count';
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      padding-right: 10px;
      span {
        border-radius: 50%;
        display: block;
        grid-area: spot;
        height: 15px;
        justify-self: center;
        width: 15px;
      }
      &.baseline span {
        background: $--color-primary;
      }
      &.comparison span {
        background: $--color-comparison;
      }
      &.baseline, &.comparison {
        .explore-tool-selection-detail-loader {
          ::v-deep .el-loading-spinner {
            margin-top: -6px;
          }
        }
      }
      .count-label {
        color: $--neutral-900;
        grid-area: count;
        font-size: 14px;
        line-height: 12px;
      }
    }
  }
  &__counts--loading {
    grid-template-columns: 1fr 1fr;
    flex: 1 0 auto;
    > label {
      justify-content: start;
    }
  }
  &__chart {
    grid-area: body;
    overflow: hidden;
  }
  &__expander {
    grid-area: expander;
    display: flex;
    .el-button {
      border: none;
      border-radius: 4px;
      flex: 1 1 auto;
      font-size: 14px;
      &:not(:hover):not(:focus):not(:active) {
        background: $--neutral-100;
        color: $--neutral-600;
      }
    }
    padding: 3px 3px;
  }
}
.explore-tool-grid {
  display: contents;
  grid-area: body;
}
.el-tooltip.disabled {
  opacity: 0.5;
}
.explore-tool__promo {
  margin-top: 20px;
}
.explore-tool__chart__loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .el-loading-parent--relative {
    width: 100%;
  }
}
</style>
