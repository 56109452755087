import { AnswerNodeContent, AnswerOutput, Node, PlainComment, Thread } from 'types/custom';
import {
  AutoCutSortType,
  AutoCutType,
  CaseHandling,
  DashboardDateResolution,
  DashboardWidgetType,
  DataSourceIntegrationRunStatus,
  DataSourceIntegrationStatus,
  DateResolution,
  FilterType,
  InputColumnType,
  IntegrationStatus,
  IntegrationType,
  OrganizationType,
  ScoreType,
  SelectionWidgetType,
  SignificantChangeType,
  SurveyDataStatus,
  SurveyStatus,
  UploadStatus,
  UploadType,
  WidgetOrderingValue,
  WidgetSortValue,
  WidgetSummaryType,
  WidgetThemeLevel,
  WorkflowActionType
} from './enums';

/* Pagination */

export interface PageRequestParams {
  page?: number;
  page_len?: number;
  sort_by?: string;
  reverse_sort?: boolean;
  search_term?: string;
}

export interface PaginatedList<T> {
  page: number;
  page_len: number;
  total_items: number;
  items: T[];
}

export interface BillingContract {
  startDate: string;
  endDate: string;
  verbatimCount: number;
  translationCount: number;
  coreUserCount: number;
  explorerUserCount: number;
  datasetCount: number;
}

export interface BillingAccount {
  id: string;
  name: string;
  hasSpecialConditions?: boolean;
  specialConditionsReason?: string;
  contracts: BillingContract[];
  workspaces: { id: string; name: string }[];
}

export interface BillingDatasetUsage {
  workspace: {
    id: string;
    name: string;
  };
  survey: {
    id: string;
    name: string;
  };
  status: string;
  verbatims: number;
  translations: number;
  byMonth: {
    name: string;
    verbatims: number;
    translations: number;
  }[];
}

export interface BillingHiddenDatasetUsage {
  count: number;
  verbatims: number;
  translations: number;
}

export interface BillingAccountMetrics {
  id: string;
  name: string;
  usageInfo: {
    startDate: string;
    endDate: string;
    verbatimsUsed: number;
    verbatimsAllocated: number;
    translationsUsed: number;
    translationsAllocated: number;
    datasetsUsed: number;
    datasetsAllocated: number;
    coreUsersUsed: number;
    coreUsersAllocated: number;
    explorerUsersUsed: number;
    explorerUsersAllocated: number;
    datasets: BillingDatasetUsage[];
    datasetsHidden: BillingHiddenDatasetUsage;
  };
  workspaces: { id: string; name: string }[];
}

export interface BillingAccountPartial {
  id: string;
  name?: string;
  workspaces?: { id: string; name: string }[];
}

/* Organization */
export interface OrganizationJson {
  name: string;
  logo?: string;
  displayId: string;
  currentUserCan: string[];
  sso_domain?: string;
  orgType: OrganizationType;
  billingAccount?: BillingAccountPartial;
  description?: string;
  primary_color?: string;
  secondary_color?: string;
}

/* Roles */

export interface RoleJson {
  id: string;
  name: string;
}
export interface RoleDetailsJson extends RoleJson {
  description: string;
  policy: PolicyJson;
  isUserSpecific?: boolean;
}

export interface PolicyJson {
  admin: string[];
  reports: PermissionJson[];
  surveys: SurveyPermissionJson[];
}

export interface PermissionJson {
  id: string;
  name?: string;
  scopes: string[];
  subResource?: string;
}

export interface SurveyPermissionJson extends PermissionJson {
  views?: PermissionJson[];
}

/* Aggregate view */

export interface AggregateView {
  id: string;
  isPreview: boolean;
  name: string;
  order: number;
}

/* Survey */

export interface Survey {
  isActive: boolean;
  configuration: string;
  hasDraftThemes: boolean;
  dataStatus: SurveyDataStatus;
  surveyStatus: SurveyStatus;
  id: string;
  isPreview: boolean;
  manualUploadAllowed: boolean;
  lastUpdated: string;
  name: string;
  order: number;
  visualizations: Visualization[];
  views: View[];
  currentUserCan: string[];
  dataSourceIntegration?: DataSourceIntegration;
  dataAlertingWorkflow?: DataAlertingWorkflow;
  dataVolume: number;
  segments?: Segment[];
}

export interface SurveyUpload {
  id: string;
  job_type: UploadType;
  status: UploadStatus;
  created: string;
  created_by: string;
  processed?: string;
}

export interface SegmentFilter {
  ids?: string[]; // Selected cut Ids for button sets and dropdowns (including hierarchical)
  startDate?: string; // Start date for date filter
  endDate?: string; // End date for date filter
  text?: string; // Search term for search filter
  name?: string; // Used in date segments to select shortcuts
  names?: { id: string; name: string }[]; // used for themes to detected correct selected theme
}

export interface SegmentFilters {
  [key: string]: SegmentFilter;
}

export type SegmentVisibility = 'public' | 'private';

export interface Segment {
  id: string;
  name: string;
  visibility: SegmentVisibility;
  configuration: {
    filters: SegmentFilters;
  };
}

export interface Visualization {
  category: string;
  id: string;
  order: number;
  isPreview: boolean;
  name: string;
}

export interface View {
  configuration: string;
  id: string;
  name: string;
  order: number;
  visualizations: Visualization[];
  children: View[];
  currentUserCan?: string[];
  segments?: Segment[];
}

export interface AnalysisFilter {
  break?: number;
  column?: number | string;
  cuts?: AnalysisFilterCut[];
  date_end?: string;
  date_start?: string;
  forceDropdown?: boolean;
  hidden?: boolean;
  id: string;
  name: string;
  type?: FilterType;
}

export interface AnalysisDateFilter extends AnalysisFilter {
  date_end: string;
  date_start: string;
  default_range?: string;
}

export interface AnalysisFilterCut {
  filter?: string;
  id?: string;
  parentId?: string;
  name: string;
  rql: string;
  type?: string;
  text?: string;
  startDate?: string;
  endDate?: string;
  children?: AnalysisFilter;
  isDefaultSelection?: boolean;
  value?: string;
}

export interface Score {
  name: string;
  score_type?: string;
  score_column?: number;
  score_options?: AnalysisScoreOptions;
  key?: string;
}

export type DateResolutionType = 'weekly' | 'monthly' | 'quarterly' | 'biannually' | 'rolling90';

export interface Dimension {
  [key: string]: {
    column: string | string[];
    name: string;
    type: string;
  };
}

export interface AnalysisScoreOptions {
  name: string;
  range: number[];
  happiness?: boolean;
  scoreCommentsOnly?: boolean;
}

interface ThreadConfig {
  id: number,
  partConfig: {
    authorType: number,
    id: number,
    partType: number,
    text: number
  },
  summarize: {
    author: string,
    text: string
  }
}

export interface AnalysisConfig {
  base_filter?: AnalysisFilter;
  comment_columns: number[] | number;
  comment_highlighting: "all" | "none";
  dataSource: string;
  date_column?: number;
  date_options: object;
  date_range: string[];
  date_resolution: DateResolutionType;
  dimensions: Dimension;
  example_columns: number | number[] | null;
  filters: AnalysisFilter[];
  max_past?: number;
  metadata_columns: MetadataColumn[];
  scopes: string[];
  score_column?: number;
  score_options?: AnalysisScoreOptions;
  score_type?: string;
  scoregraph_selector: boolean;
  scores: Array<Score>;
  sentiment?: string;
  sentiment_column: number,
  sort_column: number[];
  surveyStatus: SurveyStatus;
  threadConfig?: ThreadConfig;
  threadDisplayConfig?: ThreadDisplayOptions;
  title: string;
  user_id: string;
  user_organization: string;
  visualization_options?: VisualizationOptions;
  visualizations: VisualizationConfig[];
}

export interface VisualizationConfig {
  name: string;
  type: string;
}

export interface VisualizationOptions {
  exclude_significant_changes: boolean;
}

export interface ThreadDisplayOptions {
  author?: string;
  depth?: 'threadDepth';
  hiddenThreadParts?: string[];
}

export interface DataAlertingWorkflow {
  period: DateResolution;
  offset: number;
  warningThreshold: number;
  notification: {
    type: WorkflowActionType;
    recipients: string[];
    slackIntegrationId?: string;
  };
}

export interface DataSourceIntegration {
  configuration: object;
  integrationId?: string;
  lastFailureDate?: string;
  lastFailureReason?: string;
  lastSuccessDate?: string;
  status: DataSourceIntegrationStatus;
  type: string;
}

export interface DataSourceIntegrationRun {
  id: string;
  status: DataSourceIntegrationRunStatus;
  uploadIds: string[];
  uploadStatuses: UploadStatus[];
  submitted: string;
  processed: string;
  message?: string;
}

/* Survey configuration */
export interface SurveySynopsis {
  numColumns: number;
  numRows: number;
  columns: Column[];
}

export interface Column {
  numRows: number;
  numUniqueValues: number;
  colOptions?: { dayfirst?: boolean };
  colType: string;
  sample: string[];
  sampleHeader: string;
}

export interface InputColumn {
  name?: string;
  type: InputColumnType;
  options?: DateColumnOptions;
}

export interface DateColumnOptions {
  dayfirst: boolean;
}

export interface SurveyConfiguration {
  comment_columns?: number[] | number;
  date_column?: number;
  date_resolution?: DateResolution;
  filters?: FilterConfiguration[];
  scores?: ScoreConfiguration[];
  score_column?: number;
  score_type?: ScoreType;
  input_columns?: { [key: number]: InputColumn };
  metadata_columns?: MetadataColumn[];
  sort_column?: number[];
  has_header?: boolean;
  preprocessConfiguration?: PreprocessConfiguration;
}

export interface RedactStep {
  step: "redact";
  column: number;
}

export interface ConcatenateStep {
  step: "concatenate";
  columns: number[];
}

export interface TranslateStep {
  step: "translate";
  column: number;
}

export type PreprocessStep = RedactStep | ConcatenateStep | TranslateStep;

export interface PreprocessConfiguration {
  preprocessSteps: PreprocessStep[];
}

export interface MetadataColumn {
  name: string;
  column: number;
  isUrl?: boolean;
  isDate?: boolean;
  dateFormat?: string,
  template?: string,
  mapping?: string,
  urlTemplate?: string;
}

export interface FilterConfiguration {
  id: string;
  name: string;
  auto_cut?: AutoCutConfiguration;
  column?: number;
  type?: FilterType;
}

export interface AutoCutConfiguration {
  include_all?: boolean;
  type: AutoCutType;
  options: AutoCutOptions;
}

export interface AutoCutOptions {
  ordering?: AutoCutSortType;
  ignore_values?: string[];
  case_handling?: CaseHandling;
  rql_template?: string;
  score_type?: ScoreType;
  resolution?: DateResolution;
  dayfirst?: boolean;
}

export interface ScoreConfiguration {
  id: string;
  name: string;
  score_column: number;
  score_type: ScoreType;
  score_options?: ScoreOptions;
}

export interface ScoreOptions {
  threshold?: number;
}

/* Pricing */

export interface PricingDetails {
  price: number;
}

/* Subscriptions */

export interface Context {
  compareFilter?: string;
  compareFilterName?: string;
  filter?: string;
  filterName?: string;
  selectedPeriod: string;
  viewDashboardUrl: string;
}
export interface SubscriptionJson {
  context: Context;
  cadence: string;
  id: string;
  name: string;
  dashboardID: string;
  dashboardName: string;
  subscriptionStatus?: string;
  subscribers: { email: string; id: string; status: string }[];
  status: string;
}

/* Themes */
export type CommentColumn = number | string;

export interface ThemeJson {
  id: number;
  created: string;
  contents: string;
  last_applied?: string;
  last_updated_by: string;
  status?: {
    estimatedEnd?: string;
    isComplete: boolean;
    isSuccessful: boolean;
    message: string;
    type?: 'apply' | 'discover';
  };
  type?: string;
}

export interface DiscoveredTheme {
  title: string;
  map: string[];
  nearest: {
    base: string;
    sub: string;
  };
}

export interface DiscoveredThemesJson {
  themes: DiscoveredTheme[];
}

export interface RefreshMappedPhrasesSuggestions {
  [themeCode: string]: {
    phrase: string;
    confidence: number;
  }[];
}

export interface ThemeQualityDetails {
  overallScore: number;
  recommendations: string[];

  scores: {
    name: string;
    description: string;
    value: number;
    level: string;
    expectedRange: [number, number];
  }[];
}

/* User */
export type UserSeatType = 'core' | 'explorer' | null;

export interface User {
  activatedAt?: string;
  authId: string;
  cohort?: string;
  currentUserCan?: string[];
  email: string;
  emailHash?: string;
  firstAccess: boolean;
  firstName: string;
  id: string;
  invitedAt: string;
  lastLogin: string;
  lastLoginDate?: string;
  lastName: string;
  orgType?: string;
  organization: string;
  passwordAccessAllowed: boolean;
  picture?: string;
  preferences?: object;
  preferredName?: string;
  roles: RoleJson[];
  reports?: string[];
  scopes?: string[];
  surveys?: string[];
  subscriptions?: SubscriptionJson[];
  userSpecificRole?: RoleJson;
  seatType: UserSeatType;
}

export interface AssumedUserToken {
  accessToken: string;
}

export interface UserToken {
  id: string;
  device_name: string;
}

export interface UserRefreshToken {
  refreshToken: string;
}

/* Metrics */

export interface Metrics {
  surveys?: SurveyMetrics[];
}

export interface SurveyMetrics {
  metrics: SurveyMetric[];
  survey_id: number;
  survey_name: string;
  survey_order: number;
  survey_status: SurveyStatus;
}

export interface SurveyMetric {
  label: string;
  rowCount: number;
  themedColumnCount: number;
  translatedCount: number;
  verbatimCount: number;
  start: string;
  end: string;
}

/* Concepts */
export interface Concepts {
  include?: { [keyterm: string]: string };
  learned?: { [keyterm: string]: string };
  exclude?: { [keyterm: string]: string };
  phrases?: { [keyterm: string]: string };
}

/* Dashboards */

export type DashboardSource = AnalysisViewSource | AggregateViewSource;
export type WidgetScore = ScoreFromDetails | ScoreFromSourceId | Score;
export type SelectionWidgetConfig = SelectionDropdownConfig | SelectionScoreRankConfig | SelectionScoreBreakdownConfig;

export interface DashboardFetchResult {
  config: DashboardConfig;
  organization: string;
  version: string;
  scopes: string[];
}

export interface DashboardConfig {
  title?: string;
  baseFilter?: string;
  filters?: FilterConfiguration[];
  datePicker?: DatePicker;
  sources?: DataSources;
  source?: string;
  metadata?: {
    description?: string;
  };
  selectionWidget?: SelectionWidget;
  panels?: Panel[];
}
export interface Panel {
  title?: string;
  widgets: Widget[];
}
export interface DatePicker {
  column?: number;
  showAsDropdownOfTimeBlocks?: boolean;
  explicitTimeBlocks?: ExplicitTimeBlocks;
  resolution: DashboardDateResolution;
}
export interface ExplicitTimeBlocks {
  name: string;
  start: string;
  end: string;
  previousStart: string;
  previousEnd: string;
}
export interface DataSources {
  [k: string]: DashboardSource;
}
export interface AnalysisViewSource {
  survey: string;
  view?: string;
  visualization: string;
}
export interface AggregateViewSource {
  aggregateView: string;
}

export function isAnalysisViewSource(source: AnalysisViewSource | AggregateViewSource): source is AnalysisViewSource {
  return (source as AnalysisViewSource).survey !== undefined;
}

export interface SelectionWidget {
  source?: string;
  config?: SelectionWidgetConfig;
}

export interface SelectionDropdownConfig {
  type: SelectionWidgetType.SELECTION_DROPDOWN;
  filter: FilterConfiguration;
}

export interface SelectionScoreRankConfig {
  type: SelectionWidgetType.SELECTION_SCORERANK;
  filter: FilterConfiguration;
  score: WidgetScore;
  commentColumn?: number;
}

/**
 * Needed to calculate score information
 */
export interface ScoreFromDetails {
  column: string | number;
  type: ScoreType;
  name?: string;
  options?: {
    [k: string]: object;
  };
}

/**
 * Needed to reference a score by its internal name
 */
export interface ScoreFromSourceId {
  name?: string;
  id: string;
}
/**
 * Aggregation of scores of datasets on an aggregate view
 */
export interface SelectionScoreBreakdownConfig {
  type: SelectionWidgetType.SELECTION_SCORE_BREAKDOWN;
  filter: FilterConfiguration;
  score: WidgetScore;
}

export type DashboardWidgetConfig =
  | EmptyWidgetConfig
  | CommentsWidgetConfig
  | KeyTakeawaysWidgetConfig
  | MetadataWidgetConfig
  | Themes1WidgetConfig
  | ThemesNewWidgetConfig
  | SignificantChangesWidgetConfig
  | ThemesChangesWidgetConfig
  | ThemesCompareTwoWidgetConfig
  | ThemesSimpleWidgetConfig
  | ImpactSimpleWidgetConfig
  | ImpactChangesWidgetConfig
  | ScoreOvertimeWidgetConfig
  | ScoreWidgetConfig
  | ScoreDetailWidgetConfig
  | ScoreChangesWidgetConfig
  | SummaryWidgetConfig;

export interface Widget {
  source?: string;
  config?: DashboardWidgetConfig;
  data?: {
    [k: string]: object;
  };
}
export interface WidgetFilter {
  name: string;
  filterString: string;
}
export interface EmptyWidgetConfig {
  type: DashboardWidgetType.EMPTY;
}
export interface CommentsWidgetConfig {
  type: DashboardWidgetType.COMMENTS;
  title?: string;
  commentColumns?: number[];
  filter?: WidgetFilter;
}
export interface MetadataWidgetConfig {
  type: DashboardWidgetType.METADATA;
  title?: string;
  compareToOverall?: boolean;
  filter?: WidgetFilter;
}
export interface Themes1WidgetConfig {
  type: DashboardWidgetType.THEMES1;
  title?: string;
  compareToOverall?: boolean;
  commentColumns?: number[];
  ordering?: WidgetOrderingValue;
  sort?: WidgetSortValue;
  filter?: WidgetFilter;
  focusTheme?: string;
  numThemes?: number;
  showComments?: boolean;
  showSummary?: boolean;
}
/**
 * Themes New (in this time period)
 */
export interface ThemesNewWidgetConfig {
  type: DashboardWidgetType.THEMES_NEW;
  title?: string;
  commentColumns?: number[];
  filter?: WidgetFilter;
  baseline?: WidgetFilter;
  comparison?: WidgetFilter;
  focusTheme?: string;
  numThemes?: number;
  showComments?: boolean;
  showSummary?: boolean;
}
/**
 * Significant Changes (in this time period)
 */
export interface SignificantChangesWidgetConfig {
  type: DashboardWidgetType.SIGNIFICANT_CHANGES;
  changeType: SignificantChangeType;
  title?: string;
  commentColumns?: number[];
  filter?: WidgetFilter;
  baseline?: WidgetFilter;
  comparison?: WidgetFilter;
  focusTheme?: string;
  numThemes?: number;
  showComments?: boolean;
  showSummary?: boolean;
}
export interface ThemesChangesWidgetConfig {
  type: DashboardWidgetType.THEMES_CHANGES;
  title?: string;
  commentColumns?: number[];
  ordering?: WidgetOrderingValue;
  sort?: WidgetSortValue;
  filter?: WidgetFilter;
  baseline?: WidgetFilter;
  comparison?: WidgetFilter;
  focusTheme?: string;
  numThemes?: number;
  showComments?: boolean;
  showSummary?: boolean;
}
/**
 * Themes Compare Two (compare two different segments within the time period)
 */
export interface ThemesCompareTwoWidgetConfig {
  type: DashboardWidgetType.THEMES_COMPARE_TWO;
  baseline: WidgetFilter;
  comparison: WidgetFilter;
  title?: string;
  commentColumns?: number[];
  ordering?: WidgetOrderingValue;
  sort?: WidgetSortValue;
  focusTheme?: string;
  numThemes?: number;
  filter?: WidgetFilter;
  showComments?: boolean;
  showSummary?: boolean;
}
export interface ThemesSimpleWidgetConfig {
  type: DashboardWidgetType.THEMES_SIMPLE;
  title?: string;
  compareToOverall?: boolean;
  themeLevel?: WidgetThemeLevel;
  ordering?: WidgetOrderingValue;
  sort?: WidgetSortValue;
  commentColumns?: number[];
  filter?: WidgetFilter;
  focusTheme?: string;
  numThemes?: number;
  showComments?: boolean;
  showSummary?: boolean;
}
export interface ImpactSimpleWidgetConfig {
  type: DashboardWidgetType.IMPACT_SIMPLE;
  score: WidgetScore;
  sort: WidgetSortValue;
  title?: string;
  compareToOverall?: boolean;
  themeLevel?: WidgetThemeLevel;
  commentColumns?: number[];
  ordering?: WidgetOrderingValue;
  filter?: WidgetFilter;
  focusTheme?: string;
  numThemes?: number;
  showComments?: boolean;
  showSummary?: boolean;
}
export interface ImpactChangesWidgetConfig {
  type: DashboardWidgetType.IMPACT_CHANGES;
  score: WidgetScore;
  title?: string;
  commentColumns?: number[];
  themeLevel?: WidgetThemeLevel;
  sort?: WidgetSortValue;
  ordering?: WidgetOrderingValue;
  focusTheme?: string;
  numThemes?: number;
  filter?: WidgetFilter;
  showComments?: boolean;
  showSummary?: boolean;
}
export interface KeyTakeawaysWidgetConfig {
  type: DashboardWidgetType.KEY_TAKEAWAYS;
  score: WidgetScore;
  title?: string;
  commentColumns?: number[];
  filter?: WidgetFilter;
}
export interface ScoreChangesWidgetConfig {
  type: DashboardWidgetType.SCORE_CHANGES;
  score: WidgetScore;
  title?: string;
  commentColumns?: number[];
  filter?: WidgetFilter;
  showComments?: boolean;
  showSummary?: boolean;
}
export interface ScoreOvertimeWidgetConfig {
  type: DashboardWidgetType.SCORE_OVERTIME;
  score: WidgetScore;
  title?: string;
  compareToOverall?: boolean;
  ensureConstantScale?: boolean;
  maxPastPeriods?: number;
  filter?: WidgetFilter;
}
export interface ScoreWidgetConfig {
  type: DashboardWidgetType.SCORE;
  score: WidgetScore;
  title?: string;
  compareToOverall?: boolean;
  precision?: number;
  filter?: WidgetFilter;
}
export interface ScoreDetailWidgetConfig {
  type: DashboardWidgetType.SCORE_DETAIL;
  score: WidgetScore;
  title?: string;
  compareToOverall?: boolean;
  precision?: number;
  filter?: WidgetFilter;
}
export interface SummaryWidgetConfig {
  type: DashboardWidgetType.SUMMARY;
  title?: string;
  summaryType: WidgetSummaryType;
}

// Tags

export interface Tag {
  id: string;
  mergedId?: string;
  name: string;
  parentId?: string;
}

export interface ConnectedIntegration {
  authorizedAt: string;
  authorizedBy: string;
  id: string;
  type: IntegrationType;
  status: IntegrationStatus;
}

// Answers

type FieldId = string;
type FieldValue = string;

export type AnswersFilters = { [key: FieldId]: FieldValue[] };

interface AnswersDataSetId {
  surveyId: string;
  viewId: string | null;
  visId: string | null;
}

export interface AnswersDataSet extends AnswersDataSetId {
  title: string;
  canReadSurvey: boolean;
  globalFilters?: AnswersFilters;
}

export enum AnswersActionType {
  ask = 'ask',
  refreshAnswer = 'refreshAnswer',
  moreDepth = 'moreDepth',
  addVisualization = 'addVisualization',
  selectQuotes = 'selectQuotes',
  suggestSolutions = 'suggestSolutions',
}

export interface AskAction {
  action: AnswersActionType.ask;
  question: string;
  filters?: AnswersFilters;
  previousQuestions?: string[];
  previousContents?: string;
  collectionId: string;
}
export interface MoreDepthAction {
  action: AnswersActionType.moreDepth;
  moreDepthOn: AnswersFilters;
  previousFilters?: AnswersFilters;
  previousQuestion: string;
  previousContents: string;
  previousQuestionType?: string;
  collectionId: string;
}
export interface AddVisualizationAction {
  action: AnswersActionType.addVisualization;
  question: string;
  dataType: string;
  score?: string;
  filters: AnswersFilters;
  comparisons?: {
    name: string;
    filters: AnswersFilters;
  }[];
  context?: string;
}

export interface RefreshAction {
  action: AnswersActionType.refreshAnswer;
  question: string;
  filters?: AnswersFilters;
  previousQuestions?: string[];
  previousContents?: string;
  collectionId: string;
}
export interface SelectQuotesAction {
  action: AnswersActionType.selectQuotes;
  question: string;
  filters: AnswersFilters;
  context?: string;
}

export type TranslatedFilter = {
  filterName: string;
  filterValues: string;
};
export interface SuggestSolutionsAction {
  action: AnswersActionType.suggestSolutions;
  question: string;
  context: string;
  filters: AnswersFilters;
}

export type AnswersAction = AskAction |
  MoreDepthAction |
  AddVisualizationAction |
  RefreshAction |
  SelectQuotesAction |
  SuggestSolutionsAction;

export type AnswerSubject = {
  text: string,
  location: [from: number, to: number],
  theme: string,
  themeCode: string
};

export type AnswerMetadata = {
  count: number;
  totalCount: number;
  sentiment: number;
  sentimentCounts: {
    pos: number;
    neg: number;
    neut: number;
  };
  from?: {
    surveyId: string;
    viewId?: string;
    visId?: string;
  };
  sources?: { [sourceName: string]: AnswerMetadata };
};

export type AnswerComparison = {
  name: string;
  filters: AnswersFilters;
  inputData: InputData[];
  metadata: AnswerMetadata;
};

export type ComparisonAnswer = {
  comparisons: AnswerComparison[];
};

export type NonComparisonAnswer = {
  inputData?: InputData[];
  metadata: AnswerMetadata;
};

export type AnswersPartialAnswerWarning = {
  code: "PARTIAL_ANSWER";
  contents: string;
}

export type AnswersWarnings = AnswersPartialAnswerWarning

export type AnswerBase = {
  question: string;
  questionType: string;
  score?: string; // only if relevant to the question answered
  filters: AnswersFilters;
  supportedActions?: AnswersActionType[];
  warnings?: AnswersWarnings[];
} & (ComparisonAnswer | NonComparisonAnswer);

export type TitleAnswerPartType = {
  contents: string,
  type: 'title'
}

export type TextAnswerPartType = {
  contents: string,
  subjects: AnswerSubject[],
  type: 'text'
};

export type TableAnswerPartType = {
  table: Table,
  type: 'table'
};

export type VisualizationAnswerPartType = {
  type: 'visualization';
  contents: VisualizationSection;
};

export type SupportedMultipartAnswerParts = (TitleAnswerPartType | TextAnswerPartType | TableAnswerPartType | VisualizationAnswerPartType)[];

export type MultipartAnswerPart = {
  contents: SupportedMultipartAnswerParts,
  type: 'multipart'
};

export type TextAnswerResponse = AnswerBase & TextAnswerPartType;

export type TableAnswerResponse = AnswerBase & {
  type: 'table';
  contents: TableAnswerPartType;
};

export type MultipartAnswerResponse = AnswerBase & MultipartAnswerPart;

type AnswerSupplementaryBase = {
  question: string;
  warnings?: AnswersWarnings[];
};

export type VisualizationAnswerResponse = VisualizationAnswerPartType & AnswerSupplementaryBase;

export type SuggestionsAnswerResponse = AnswerSupplementaryBase & {
  type: 'suggestions';
  contents: string;
};

export type InputData = {
  categories: string[];
  sentiment: string;
  source: string;
  text: string;
  themes: string[];
  isPinned?: boolean;
  references?: Reference[];
}

export type Reference = {
  author: string;
  excerpts: string[];
};

export type QuotesAnswerResponse = AnswerSupplementaryBase & {
  type: 'quotes';
  inputData?: InputData[];
  comparisons?: { inputData: InputData[] }[];
};

export type BarVistype = 'bar' | 'line';

export type VisSeries = {
  name?: string;
  components?: {
    // this should really be called value because it is presupposing that bar charts are actually theme volume charts
    // retaining name for backwards compatibility
    count: number;
    //  We're using source which is the dataset name rather than id, this is an unstable identifier as it can change
    // Therefore do not use this for navigation, only display
    source: string;
    // volume only applies to theme volume charts. Do not rely on this being present
    volume?: number;
  }[][];
  // This is the actual values. Graphs should not require 'components'.
  // They should never show values that aren't in this array
  values: number[];
};

export type BarVisualizationSection = {
  type: 'visualization';
  title: string;
  visType: BarVistype;
  data: {
    series?: VisSeries[],
    labels: string[];
    xLabel: string;
    yLabel: string;
    units?: string; // added later, can't assume is present
  } & VisSeries; // due to backwards compatibility, saved visualizations may have a single series on the actual object
};

export type WaterfallVisualizationSection = {
  type: 'visualization';
  title: string;
  visType: 'waterfall';
  data: WaterfallData;
};

export type VisualizationSection = BarVisualizationSection | WaterfallVisualizationSection;

export type AnswerResponse
  = TextAnswerResponse
  | TableAnswerResponse
  | MultipartAnswerResponse;

export function isComparisonResponse(response: AnswerResponse): response is AnswerResponse & ComparisonAnswer {
  return 'comparisons' in response;
}

export function isNonComparisonResponse(response: AnswerResponse): response is AnswerResponse & NonComparisonAnswer {
  return 'metadata' in response && !('comparisons' in response);
}

export function isQuotesAnswerResponse(response: AnswerNodeContent): response is QuotesAnswerResponse {
  return response.type === 'quotes';
}

export type AnswerSupplementaryResponse
  = VisualizationAnswerResponse
  | SuggestionsAnswerResponse
  | QuotesAnswerResponse;

export type OutputMessage = {
  message: 'output',
  data: AnswerResponse[]
};

export type AnswersErrorBase = {
  message: 'error'
  error: string
};

type AnswersQuestionOutsideDomainError = AnswersErrorBase & {
  errorCode: 'QUESTION_OUTSIDE_DOMAIN',
};
type AnswersNoDatasetSelectedError = AnswersErrorBase & {
  errorCode: 'NO_DATASETS_SELECTED',
};
type AnswersInternalServerError = AnswersErrorBase & {
  errorCode: 'INTERNAL_SERVER_ERROR',
};
type AnswersContentModerationError = AnswersErrorBase & {
  errorCode: 'CONTENT_MODERATION_ERROR',
};
type AnswersQuestionNotClearError = AnswersErrorBase & {
  errorCode: 'QUESTION_NOT_CLEAR',
};
type AnswersQuestionNotSupportedError = AnswersErrorBase & {
  errorCode: 'QUESTION_NOT_SUPPORTED',
  data: {
    suggestions: {
      title: string,
      questions: string[]
    }[]
  }
};
type AnswersNoDataError = AnswersErrorBase & {
  errorCode: 'NO_DATA',
  data: {
    filters: AnswersFilters
  }
};
type AnswersNotEnoughDataError = AnswersErrorBase & {
  errorCode: 'NOT_ENOUGH_DATA',
  data: TextAnswerResponse[]
};

export type ErrorMessage
  = AnswersInternalServerError
  | AnswersQuestionNotClearError
  | AnswersContentModerationError
  | AnswersQuestionNotSupportedError
  | AnswersNoDataError
  | AnswersNoDatasetSelectedError
  | AnswersNotEnoughDataError
  | AnswersQuestionOutsideDomainError;

type InterimMessage = {
  message: 'interimResults',
  data: {
    message: string
  }
};

export type AnswerMessage = InterimMessage | OutputMessage | ErrorMessage;

export type SavedAnswerCandidate = {
  id?: string,
  duplicatedFrom?: string,
  title: string,
  answer: {
    dataSets: AnswersDataSet[],
    filters: SegmentFilters,
    question: string,
    contents: string | TableAnswerContents | SupportedMultipartAnswerParts,
    data: Node<AnswerOutput>
  }[],
  sharingStatus: string[]
};

export type SavedAnswer = SavedAnswerCandidate & {
  id: string,
  createdAt: string;
  createdBy: string;
  updatedAt: string;
};

export type SavedAnswerMeta = {
  id: string;
  datasetNames: string[] | string;
  synopsis: string;
  title: string;
  updatedAt?: Date;
  createdAt?: Date;
  createdBy: string;
};

export type SavedAnswerResponse = {
  createdAt: string;
  createdBy: string;
  data: {
    id: string;
    answer: {
      contents: string | TableAnswerContents | SupportedMultipartAnswerParts,
      data: {
        children: Node<AnswerOutput>[];
        item: Node<AnswerOutput>;
      }
      dataSets: AnswersDataSet[],
      filters: SegmentFilters,
      question: string,
    }[],
    title: string;
  },
  datasetNames: string[];
  id: string;
  organizationId: string;
  synopsis: string;
  title: string;
  updatedAt: string;
  version: string;
  sharingStatus: string[];
};

export type AnswersAuditRecord = {
  createdBy: string;
  recordedAt: string;
  collectionId?: string;
  question: string;
  success: boolean;
  result: string;
}

interface TableColumn {
  name: string;
  accessor: string;
}

interface DataRow {
  [key: string]: string | number;
}

interface Table {
  columns: TableColumn[];
  data: DataRow[];
}

interface TableAnswerContents {
  table: Table;
}

type WaterfallDataValue = {
  score: number,
  label: string,
  total: number
};

type WaterfallTheme = {
  title: string;
  label: string;
  value: number;
};

export type WaterfallDataPoint = {
  title: string;
  value: number;
  themes?: WaterfallTheme[];
  count: number;
  code: string;
  previousCount: number;
  previousScore: number;
  score: number;
  isOther?: boolean;
  isNoText?: boolean;
};

export type WaterfallData = {
  dataPoints: WaterfallDataPoint[]
  previousData: WaterfallDataValue;
  currentData: WaterfallDataValue;
};

export type Cluster<C extends PlainComment | Thread> = Record<
  string,
  Array<[
    snippet: string,
    sentiment: number,
    comment: C
  ]>
>;


export interface SummarizationResponse {
  data: {
    summary: string;
    clusters: Cluster<PlainComment | Thread>;
    statistics: {
      clusteredSentences: number;
      totalComments: number;
    }
  },
  status: 'success';
}
