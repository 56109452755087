<template>
  <div class="dashboard">
    <dashboard-header
      :date-string="dateString"
      :dashboard-id="dashboardId"
      :title="title"
      :context="context"
      :config="config"
      :version="version"
    />
    <div class="filter-container">
      <dashboard-filters
        v-if="filters && dashboardId.length > 0"
        :filters="filters"
        :dashboard-id="dashboardId"
        :base-filter="baseFilter"
        :source="currentSource"
        @setQuery="setQuery"
        @setFilterName="setFilterName"
        @setFilters="setFilters"
      />
      <div class="dashboard-filters">
        <div
          v-if="dateConfig"
          class="dashboard-date-selection dashboard-filter"
        >
          <dashboard-filter-date
            v-if="dashboardId.length > 0"
            :sources="sources"
            :date-config="dateConfig"
            :dashboard-id="dashboardId"
            @selectionChanged="dateSelectionChanged"
          />
        </div>
      </div>
    </div>
    <div class="filter-container selection-widget-container">
      <selection-widget
        v-if="!isScoreRank && !isScoreBreakdown"
        :dashboard-id="dashboardId"
        :context="context"
        :source="currentSource"
        @updateCompareTo="updateCompareTo"
        @onReady="onCompareToReady"
      />
    </div>
    <link-to-answer
      v-if="canAccessAnswer && canSeeAnswerBanners && !isAnswersBannerDismissed && !answersBannerLocallyDismissed"
      :org-id="orgId"
      :sources="dashboardSources"
      :location="AnswersBannerLocations.Dashboard"
      :on-dismiss="dismissBanner"
    />
    <div class="container">
      <dashboard-description />
      <selection-score-breakdown
        v-if="isScoreBreakdown"
        :config="{ config: activeDashboardUIStore.selectionWidget }"
        :dashboard-id="dashboardId"
        :context="context"
        :filter-string="filterString"
        :filter-names="filterName"
        :compare-filter="compareFilter"
        :compare-filter-name="compareFilterName"
        :source="currentSource"
        @updateCompareTo="updateCompareTo"
        @onReady="onCompareToReady"
      />
      <selection-score-rank
        v-if="isScoreRank"
        :config="{ config: activeDashboardUIStore.selectionWidget }"
        :dashboard-id="dashboardId"
        :context="context"
        :filter-string="filterString"
        :filter-names="filterName"
        :compare-filter="compareFilter"
        :compare-filter-name="compareFilterName"
        :base-filter="baseFilter"
        :date-filter="dateFilter"
        :source="currentSource"
        @updateCompareTo="updateCompareTo"
        @onReady="onCompareToReady"
      />
      <dashboard-panel
        v-for="(panel, index) in panels"
        :key="`${index}-${panel.title || ''}`"
        :config="panel"
        :context="context"
        :dashboard-id="dashboardId"
        :current-source="currentSource"
        :filter-string="filterString"
        :filter-names="filterName"
        :compare-filter="compareFilter"
        :compare-filter-name="compareFilterName"
        :base-filter="baseFilter"
        :date-filter="dateFilter"
      />
    </div>
  </div>
</template>

<script>
import { get, isEmpty, join, reduce, values } from 'lodash';
import { stringify } from 'query-string';
import moment from 'moment';

import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';

import queryBuilder from 'vue/libs/queryBuilder';
import DashboardDescription from 'vue/dashboards/Dashboard/Components/DashboardDescription.vue';
import DashboardHeader from './DashboardHeader.vue';
import DashboardPanel from 'vue/dashboards/Dashboard/Panel.vue';
import DashboardFilterDate from 'vue/dashboards/Dashboard/DateFilter.vue';
import DashboardFilters from 'vue/dashboards/Dashboard/Filters.vue';
import SelectionScoreRank from 'vue/dashboards/Dashboard/Selectors/SelectionScoreRank.vue';
import SelectionScoreBreakdown from 'vue/dashboards/Dashboard/Selectors/SelectionScoreBreakdown.vue';
import SelectionWidget from 'vue/dashboards/Dashboard/Selectors/SelectionWidget.vue';
import { AnswersBannerLocations, LinkToAnswer } from 'components/Banners/LinkToAnswer';
import { ReactInVue } from 'vuera';

import { describeDateRange, utcToDateString } from 'lib/date';
import analytics from 'lib/analytics';
import { SelectionWidgetType } from 'api/enums';
import {
  getSubscriptionStore,
  getUserStore,
  getActiveDashboardUIStore,
  getUrlParametersStore,
  getAnswersStore,
  getOrganizationStore
} from 'stores/RootStore';
import { DismissibleElements } from 'stores/UserStore';

export default {
  name: 'Dashboard',
  components: {
    DashboardDescription,
    DashboardFilters,
    DashboardFilterDate,
    DashboardPanel,
    SelectionScoreRank,
    SelectionScoreBreakdown,
    DashboardHeader,
    SelectionWidget,
    LinkToAnswer: ReactInVue(LinkToAnswer),
  },
  props: {
    config: { default: undefined, type: Object },
    version: { default: undefined, type: String },
  },
  data() {
    return {
      urlParametersStore: getUrlParametersStore(),
      answersStore: getAnswersStore(),
      organizationStore: getOrganizationStore(),

      compareFilter: undefined,
      compareFilterName: undefined,
      compareFilterReady: false,
      dateFilter: null,
      digestRequested: false,
      favoritingDashboard: false,
      filterNames: {},
      filterName: '',
      filterString: '',
      popperOptions: { boundariesElement: this.$parent._uid },
      search: {},
      searchFilters: {},
      searchComparison: {},
      updateLocationTimeout: undefined,
      userStore: getUserStore(),
      subscriptionStore: getSubscriptionStore(),
      activeDashboardUIStore: getActiveDashboardUIStore(),
      toggleFavError: undefined,
      AnswersBannerLocations,
      DismissibleElements,
      answersBannerLocallyDismissed: false,
    };
  },
  computed: {
    canFavDashboards() {
      return this.dashboardId;
    },
    canSeeDevOnlyTools() {
      return FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_DEV_ONLY_TOOLS);
    },
    canSeeAnswerBanners() {
      return FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_ANSWER_BANNERS);
    },
    canAccessAnswer() {
      return this.answersStore.userHasAccessToAnswers;
    },
    orgId() {
      return this.organizationStore.orgId;
    },
    configLocation() {
      return this.activeDashboardUIStore.configLocation;
    },
    isScoreRank() {
      return this.activeDashboardUIStore.selectionWidgetType === SelectionWidgetType.SELECTION_SCORERANK;
    },
    isScoreBreakdown() {
      return this.activeDashboardUIStore.selectionWidgetType === SelectionWidgetType.SELECTION_SCORE_BREAKDOWN;
    },
    isAnswersBannerDismissed() {
      return this.userStore.isDismissed(DismissibleElements.AnswersDashboardBanner);
    },
    context() {
      const { dateFilter, filterString: filter, filterNames, baseFilter, compareFilter, compareFilterName } = this;

      // if the date filter isn't ready yet, stop
      if (!dateFilter) {
        return null;
      }

      const context = {
        viewDashboardUrl: this.viewDashboardUrl,
      };
      if (filter) {
        context['filter'] = filter;
        context['currentFilters'] = reduce(
          filterNames,
          (result, value, name) => {
            if (value) {
              result.push(`${name}: ${value}`);
            }
            return result;
          },
          [],
        );
        context['filterName'] = context['currentFilters'].join(', ');
      }

      if (compareFilter) {
        // compareFilter needs to have the filterString applied too
        const compareFilterRql = queryBuilder.appendToFilter(filter, compareFilter);
        context['compareFilter'] = compareFilterRql;
        context['currentCompareFilters'] = compareFilterName;

        // and compareFilterName should have the overall filter name included too
        const filterName = context['filterName'];
        let compareFilterNameAppended = compareFilterName;
        if (filterName) {
          compareFilterNameAppended = `${filterName}, ${compareFilterName}`;
        }
        context['compareFilterName'] = compareFilterNameAppended;
      }
      if (baseFilter) {
        context['baseFilter'] = baseFilter;
      }

      const startDateString = get(dateFilter, 'selected.startDate');

      if (startDateString) {
        const startDate = utcToDateString(startDateString);
        const endDate = utcToDateString(get(dateFilter, 'selected.endDate'));
        context['selectedPeriod'] = `${startDate}:${endDate}`;

        // there are situations where we want to override the previous period
        const previousStartDateString = get(dateFilter, 'previous.startDate');
        if (previousStartDateString) {
          const previousStartDate = utcToDateString(previousStartDateString);
          const previousEndDate = utcToDateString(get(dateFilter, 'previous.endDate'));
          context['previousPeriod'] = `${previousStartDate}:${previousEndDate}`;
        }
      }
      return context;
    },
    currentSource() {
      return this.activeDashboardUIStore.getSourceUrl(this.sourceKey);
    },
    dashboardSources() {
      return Object.values(this.activeDashboardUIStore.sources);
    },
    sources() {
      return Object.keys(this.activeDashboardUIStore.sources).reduce((acc, source) => {
        const url = this.activeDashboardUIStore.getSourceUrl(source);
        return (typeof url === 'string') ? [...acc, url] : acc;
      }, [])
    },
    dateRange() {
      const startDate = get(this, 'dateFilter.selected.startDate');
      const endDate = get(this, 'dateFilter.selected.endDate');

      if (startDate && endDate) {
        const start = moment(startDate).format('YYYY-MM-DD');
        const end = moment(endDate).format('YYYY-MM-DD');
        return `${start}:${end}`;
      } else {
        return undefined;
      }
    },
    favDashboard() {
      const { defaultDashboard } = this;
      const dashboard = this.userStore.defaultDashboard || defaultDashboard;
      return this.dashboardId === dashboard;
    },
    filters() {
      return get(this, 'config.filters', null);
    },
    filtersReady() {
      const { compareFilterReady } = this;
      if (this.activeDashboardUIStore.selectionWidget) {
        return compareFilterReady;
      } else {
        return true;
      }
    },
    baseFilter() {
      return get(this, 'config.baseFilter', null);
    },
    dateConfig() {
      const dateConfig = get(this, 'config.datePicker', null);
      // if there isn't a dateConfig, then we should initialize the dateFilter to nothing
      // this is so that listeners will know to stop waiting for a datefilter
      if (!dateConfig) {
        // @FIXME side effects
        this.dateFilter = {}; // eslint-disable-line vue/no-side-effects-in-computed-properties
      }
      return dateConfig;
    },
    dateString() {
      const start = get(this, 'dateFilter.selected.startDate', null);
      const end = get(this, 'dateFilter.selected.endDate', null);

      const range = describeDateRange(start, end);
      return range ? `${range}` : '';
    },
    dashboardId() {
      const re = /api\/dashboard\/([^/]+)\/config/;
      const location = this.configLocation || '';
      const matches = location.match(re);

      if (matches) {
        return matches[1];
      } else {
        return '';
      }
    },
    sourceKey() {
      return get(this, 'config.source', 'default');
    },
    title() {
      return this.config ? this.config.title : '';
    },
    panels() {
      return this.config ? this.config.panels : [];
    },
    shareUrlBase() {
      return this.urlParametersStore.shareUrlBase;
    },
    viewDashboardUrl() {
      if (isEmpty(this.search)) {
        return this.shareUrlBase;
      } else {
        return `${this.shareUrlBase}?${stringify(this.search)}`;
      }
    },
  },
  methods: {
    onCompareToReady() {
      this.compareFilterReady = true;
    },
    openSubscriptionWindow() {
      const { context, title: dashboardName } = this;
      this.subscriptionStore.subscriptionRequest = { context, dashboardName };

      analytics.track('Dashboard: Open Subscription Window', {
        category: 'Dashboard',
        version: this.version,
      });
    },
    setFilterName(filterName, filterValue) {
      this.filterNames[filterName] = filterValue;
      this.filterName = join(values(this.filterNames), ', ');
    },
    setFilters(filters) {
      this.searchFilters = filters;

      this.updateLocation();
    },
    setQuery(queryString) {
      this.filterString = queryString;
    },
    setFav: async function () {
      this.favoritingDashboard = true;
      const defaultDashboard = this.dashboardId;
      try {
        await this.userStore.setPreferences({ defaultDashboard });
      } catch (e) {
        this.toggleFavError = e;
      } finally {
        this.favoritingDashboard = false;
      }
    },
    dateSelectionChanged(selection) {
      this.dateFilter = selection;

      this.updateLocation();
    },
    updateCompareTo(compareRql, compareFilterName, compareParams) {
      this.compareFilter = compareRql;
      this.compareFilterName = compareFilterName;

      this.searchComparison = compareParams;
      this.updateLocation();
    },
    // Update the `search` property & publish it to the parent frame
    updateLocation() {
      const { dateRange, search, searchFilters, searchComparison, filtersReady, updateLocationTimeout } = this;
      const filters = { ...searchFilters };

      if (dateRange) {
        filters['date_range'] = dateRange;
      }
      search.filters = stringify(filters);
      if (!isEmpty(searchComparison)) {
        search.compare = stringify(searchComparison);
      }
      clearTimeout(updateLocationTimeout);
      this.updateLocationTimeout = setTimeout(() => {
        if (filtersReady) {
          const { query: q, path } = this.$route;
          const query = Object.assign({}, q, search);
          this.$router.push({ path, query }).catch(() => ({}));
        } else {
          setTimeout(this.updateLocation, 100);
        }
      }, 10);
    },
    dismissBanner() {
      this.answersBannerLocallyDismissed = true;
      this.userStore.dismissElement(DismissibleElements.AnswersDashboardBanner);
    },
  },
};
</script>


<style lang="scss" scoped>
@import './dashboard.scss';
</style>
