import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnalysisConfig, AnswersDataSet } from 'api/interfaces';
import classNames from 'classnames';
import { Button } from 'components/Shared/Button';
import OnboardingBeacon from 'components/Shared/OnboardingBeacon';
import analytics from 'lib/analytics';
import { compose } from 'lib/composeHOCs';
import { reduceNodes } from 'lib/node-helpers';
import { IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import AnalysisConfigStore from 'stores/AnalysisConfigStore';
import { AnswerError, AnswersStoreInterface } from 'stores/AnswersStore';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import { UserStoreInterface } from 'stores/UserStore';
import { AnswersUIStoreInterface } from 'stores/ui/AnswersUIStore';
import { fromParts } from 'stores/utils/answers-helpers';
import { AnswerModals, AnswerNode } from 'types/custom';
import { AnswerSkeleton } from './components/AnswerSkeleton';
import { AnswersError } from './components/AnswersError';
import { AskForm } from './components/AskForm';
import { BoundaryError } from './components/BoundaryError';
import { DeleteModal } from './components/DeleteModal';
import { EditableHeader } from './components/EditableHeader';
import { EmailModal } from './components/EmailModal';
import { EmptyNoDatasets } from './components/EmptyNoDatasets';
import { FinalizeEmailModal } from './components/FinalizeEmailModal';
import { SavedAnswers } from './components/SavedAnswers';
import { SharingButton } from './components/SharingButton';
import { SharingModal } from './components/SharingModal';
import { TreeItem, UserAction } from './components/TreeItem';
import { Welcome } from './components/Welcome';
import './main.scss';
import { Provider } from './utils/context';
import { dataSetToViewSource } from './utils/datasetToSource';
import { focusAskFormInput } from './utils/focusAskFormInput';
import { isOnlyOutputItem } from './utils/isOnlyOutputItem';
import { NestedConfigs, nestConfigs } from './utils/transformConfigs';

type Props = {
  answersStore: AnswersStoreInterface,
  answersUIStore: AnswersUIStoreInterface,
  analysisConfigStore: AnalysisConfigStore,
  surveyStore: SurveyStoreInterface,
  userStore: UserStoreInterface,
} & RouteComponentProps<{ orgId: string, savedAnswerId?: string }>;

interface State {
  lastRevealedNodeId: string | null;
}

const withHocs = compose(
  (c: React.ComponentClass) => withErrorBoundary(c, { fallback: <BoundaryError /> }),
  withRouter,
  inject('answersStore', 'answersUIStore', 'analysisConfigStore', 'surveyStore', 'userStore'),
  observer,
);

class AnswersClass extends React.Component<Props, State> {
  disposer: IReactionDisposer;

  state = {
    lastRevealedNodeId: null,
  };

  componentDidMount(): void {
    analytics.startRecording(false);

    const { params } = this.props.match;

    this.props.answersStore.reset();
    this.props.answersStore.init();

    this.props.answersUIStore.selectedAnswerChanged(
      params.savedAnswerId
    );

    this.disposer = reaction(
      () => this.props.answersStore.flatDatasets,
      (flatDatasets) => this.props.answersUIStore.selectInitialDataset(flatDatasets),
      { fireImmediately: true }
    );
  }

  componentWillUnmount(): void {
    analytics.stopRecording();
    this.props.answersStore.reset();
    this.props.answersUIStore.reset();
    this.disposer();
  }

  async componentDidUpdate(prevProps: Props) {
    const { params } = this.props.match;

    if (prevProps.match.params.savedAnswerId !== params.savedAnswerId) {
      await this.props.answersUIStore.selectedAnswerChanged(
        params.savedAnswerId
      );
    }
    if (this.state.lastRevealedNodeId !== this.props.answersUIStore.lastRevealedNodeId) {
      this.scrollToNode(this.props.answersUIStore.lastRevealedNodeId);
      this.setState({ lastRevealedNodeId: this.props.answersUIStore.lastRevealedNodeId });
    }
  }

  scrollToNode(id: string | null) {
    if (id) {
      document.querySelector(`[data-node-id="${ id }"]`)?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  focusByNodeId(id: string) {
    (document.querySelector(`[data-node-id="${ id }"]`) as HTMLElement)?.focus();
  }

  getAnswerNodesVisibleChildren(answerNode: AnswerNode) {
    return answerNode?.children.filter((child: AnswerNode) =>
      child.item.isVisible
    );
  }

  updateChildNodeFocus() {
    const nodes = this.props.answersUIStore.nodes;
    const currentNode = nodes[nodes.length - 1];

    const visibleChildren = this.getAnswerNodesVisibleChildren(currentNode);

    const previousChildSiblingId = visibleChildren[visibleChildren.length - 2]?.item.id;

    if (previousChildSiblingId) {
      this.focusByNodeId(previousChildSiblingId);
    } else {
      this.focusByNodeId(currentNode.item.id);
    }
  }

  updateParentNodeFocus() {
    const nodes = this.props.answersUIStore.nodes;
    const previousNode = nodes[nodes.length - 2];

    const previousChildren = this.getAnswerNodesVisibleChildren(previousNode);

    if (previousChildren?.length > 0) {
      this.focusByNodeId(previousChildren[previousChildren.length - 1].item.id);
      return;
    }

    if (previousNode) {
      this.focusByNodeId(previousNode.item.id);
      return;
    }

    (document.getElementById('ask-form-input') as HTMLElement)?.focus();
  }

  async onItemDispatch(action: UserAction) {
    const uiStore = this.props.answersUIStore;

    switch (action.type) {
      case 'answers-action': {
        if (action.payload.node.item.state !== 'output') { return; }

        await uiStore.dispatchAction(
          action.payload.action,
          action.payload.node.item.dataSets,
          action.payload.node.item.filters || {},
          action.payload.node.item.id,
        );
        break;
      }

      case 'refresh-answer': {
        if (action.payload.node.item.state !== 'output' || !('question' in action.payload.action)) {
          return;
        }

        uiStore.refreshAnswer({
          dataSets: action.payload.node.item.dataSets,
          filters: action.payload.node.item.filters || {},
          originNodeId: action.payload.node.item.id,
          question: action.payload.action.question,
        });

        break;
      }
      case 'hide-click': {
        this.updateChildNodeFocus();

        uiStore.updateItem({
          ...action.payload.node.item,
          isVisible: false
        });
        uiStore.handleAnswerModification();

        break;
      }
      case 'remove-click': {

        const item = action.payload.node.item;
        const isLast = isOnlyOutputItem(item, uiStore.nodes);

        this.updateParentNodeFocus();

        if (isLast) {
          this.navigateTo();
        } else {
          uiStore.removeItem(action.payload.node.item);
          uiStore.handleAnswerModification();
        }

        break;
      }
      case 'suggest-click': {
        const suggestion = action.payload;
        this.handlePresetQuestionClick(suggestion);
        break;
      }
      case 'toggle-pin': {
        const nodeId = action.payload.node.item.id;
        uiStore.toggleExamplePin(nodeId, action.payload.example);
        break;
      }
      default: break;
    }
  }

  handlePresetQuestionClick(question: string) {
    const uiStore = this.props.answersUIStore;
    uiStore.updateAsk({ question });
    focusAskFormInput();
  }

  async onFormSubmit() {
    const uiStore = this.props.answersUIStore;

    const hasMadeAnAnswer = uiStore.nodes.some(n => n.item.state === 'output');
    analytics.track('Answers: Ask Form', { 'IsFollowUp': hasMadeAnAnswer, 'WasTyped': true });

    await uiStore.ask();
  }

  onTitleChange(title: string) {
    const uiStore = this.props.answersUIStore;
    uiStore.updateTitle(title);
  }

  async downloadAnswer() {

    const savedAnswerId = this.props.match.params.savedAnswerId;

    if (!savedAnswerId) {
      // No error handling, this is dev-only
      return;
    }

    const answerUrl = window.location.href;
    const { user } = this.props.userStore;

    try {

      const savedAnswer = await this.props.answersStore.fetchAnswer(savedAnswerId);

      if (!savedAnswer) {
        return;
      }

      // All the datasets in the saved answer.
      const datasets: AnswersDataSet[] = reduceNodes(
        (result: AnswersDataSet[], node) => [...result, ...node.item.dataSets],
        fromParts(savedAnswer.answer),
        []
      );

      const entries = await Promise.all(datasets.map(async (dataset) => {
        const source = dataSetToViewSource(dataset);
        const config = await this.props.analysisConfigStore.getConfig(source);
        return [dataset, config];
      }));

      const filteredEntries = entries.filter(
        (value): value is [AnswersDataSet, AnalysisConfig] => value[1] !== undefined
      );

      const configs: NestedConfigs = nestConfigs(filteredEntries);

      const staticAnswerResponse = await fetch('/static-answer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          savedAnswer,
          configs,
          answerUrl,
          user
        }),
      });

      const staticAnswerHtml = await staticAnswerResponse.text();

      const blob = new Blob([staticAnswerHtml], { type: 'text/html' });
      const url = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = 'answer.html';
      downloadLink.click();
      downloadLink.remove();
    } catch (error) {
      console.error('Failed to download answer', error);
    }
  }

  getContent() {

    const { params } = this.props.match;
    const uiStore = this.props.answersUIStore;
    const store = this.props.answersStore;
    const surveyStore = this.props.surveyStore;
    const hasDatasets = uiStore.dataSets.length > 0;
    const isEmpty = uiStore.nodes.length === 0;

    const shouldShowReadOnly = uiStore.shouldShowReadOnly;

    const loaderClasses = classNames('answers-main__loader', {
      'answers-main__loader--fetching-answers': store.isFetchingAnswer && surveyStore.fetchedSurveys
    });
    // `fetching-answer` mod is for when fetching answers we need to show loader in a different position

    if (!surveyStore.fetchedSurveys) {
      return (
        <Loader
          className={loaderClasses}
          size="large"
          active={true}
        />
      );
    }

    if (store.isFetchingAnswer) {
      return <AnswerSkeleton />;
    }

    if (!shouldShowReadOnly && !hasDatasets) {
      return <EmptyNoDatasets />;
    }

    if (store.error === AnswerError.FAILED_TO_FETCH_ANSWER) {
      return (
        <AnswersError
          className="answers-error--missing"
          tracking={{
            event: "Error: Answers",
            eventOptions: {
              "title": "An unknown error occurred",
              "description": "Couldn't fetch answer."
            }
          }}
        >
          <p>Couldn't fetch answer.</p>
        </AnswersError>
      );
    }

    if (params.savedAnswerId && isEmpty) {
      return (
        <AnswersError
          className="answers-error--missing"
          tracking={{
            event: "Error: Answers",
            eventOptions: {
              "title": "An unknown error occurred",
              "description": "This saved answer could not be loaded, please refresh."
            }
          }}
        >
          <p>This saved answer could not be loaded, please refresh.</p>
        </AnswersError>
      );
    }

    if (isEmpty) {
      return (
        <Welcome
          onQuestionClick={(question) => this.handlePresetQuestionClick(question)}
        />
      );
    }

    const disableCloseButton = store.isSavingAnswer && !this.props.match.params.savedAnswerId;

    const canSeeAnswersSharing = !shouldShowReadOnly;

    const haveAccessToAnswers = store.userHasAccessToAnswers;

    return (
      <>
        <header
          data-testid="answer-header"
          className="answers__editable-header ob-answers-editable-header"
        >

          <div className="answers__container answers__editable-container">
            <EditableHeader
              title={uiStore.title}
              isReadOnly={shouldShowReadOnly}
              onChange={title => this.onTitleChange(title)}
            />
            {canSeeAnswersSharing &&
              <SharingButton
                isSharingAllowed={uiStore.isSharingAllowed}
                orgName={uiStore.currentOrgName}
                onShare={() => {
                  uiStore.setMenuSavedAnswerId(uiStore.selectedSavedAnswerId);
                  uiStore.setCurrentModal(AnswerModals.Share);
                }}
              />
            }
          </div>
        </header>
        <ul className={classNames('answers__content', 'answers__container',
          { 'answers__read-only': shouldShowReadOnly })}>
          {
            uiStore.nodes
              .filter(node => node.item.isVisible)
              .map((node) => (
                <TreeItem
                  key={node.item.id}
                  savedAnswerId={uiStore.selectedSavedAnswerId}
                  getSiblings={() => uiStore.nodes}
                  node={node}
                  dispatch={(action) => this.onItemDispatch(action)}
                  isReadOnly={shouldShowReadOnly}
                  disableCloseButton={disableCloseButton}
                />
              )
              )
          }
        </ul>
        {shouldShowReadOnly &&
          <footer
            className={classNames('answers__footer', 'answers__container', {
              'answers__footer-no-sidebar': !haveAccessToAnswers
            })}
          >
            <div className="answers__footer-text">
              <span className="answers__footer-highlighted-text">
                Shared by {uiStore.currentAnswerUser}.
              </span>
              {haveAccessToAnswers && <span>Make a copy to modify it.</span>}
            </div>
            {haveAccessToAnswers && uiStore.isDuplicating &&
              <Loader size="small" inline active />
            }
            {haveAccessToAnswers && !uiStore.isDuplicating &&
              <Button
                variant="primary"
                onClick={() => {
                  uiStore.duplicate()
                  analytics.track('Answers: Duplicate shared answer', {});
                }}
                size="medium"
              >
                <FontAwesomeIcon
                  className="icon answers__footer-icon"
                  icon="clone"
                  fixedWidth={true}
                /> Make a copy
              </Button>
            }
          </footer>
        }
      </>
    );

  }

  navigateTo(id?: string) {
    const { params } = this.props.match;
    const pathname = id
      ? `/c/${ params.orgId }/answers/${ id }`
      : `/c/${ params.orgId }/answers`;
    this.props.history.push(pathname);
  }

  deleteAnswer() {
    const store = this.props.answersStore;
    const uiStore = this.props.answersUIStore;

    const id = uiStore.menuSavedAnswerId;
    if (!id) {
      return;
    }

    store.deleteAnswer(id);

    uiStore.setCurrentModal(AnswerModals.None);
    uiStore.setMenuSavedAnswerId(null);
    // navigate back to answers default page
    this.navigateTo();
  }

  async handleSendEmail(emails: string[], emailSubject: string, emailBody: string) {
    const store = this.props.answersStore;
    const uiStore = this.props.answersUIStore;

    if (uiStore.menuSavedAnswerId) {
      await store.sendEmail(uiStore.menuSavedAnswerId, emails, emailSubject, emailBody);
    }

    if (store.hasSentEmail) {
      uiStore.setCurrentModal(AnswerModals.Finalize);
    }
  }

  fetchEmailPreview = () => {
    const uiStore = this.props.answersUIStore;
    const store = this.props.answersStore;

    if (uiStore.menuSavedAnswerId) {
      store.fetchEmailPreview(uiStore.selectedSavedAnswerId);
    }
  }

  focusNextSavedAnswer() {
    const nextSavedAnswerItem = document.querySelector('.saved-answers__item');
    if (nextSavedAnswerItem instanceof HTMLLIElement) {
      nextSavedAnswerItem.focus();
    } else {
      focusAskFormInput();
    }
  }

  toggleSharing = () => {
    const { answersUIStore } = this.props;
    if (answersUIStore) {
      answersUIStore.setIsSharingAllowed(!(answersUIStore.isSharingAllowed));
    }
  }

  render() {
    const uiStore = this.props.answersUIStore;
    const store = this.props.answersStore;

    const hasMadeAnAnswer = uiStore.nodes.some(n => n.item.state === 'output');
    const placeholder = hasMadeAnAnswer ? 'Ask a follow-up question' : 'Ask a question';

    const { params } = this.props.match;

    const haveAccessToAnswers = store.userHasAccessToAnswers;

    const activeMenuMeta = store.savedAnswerMetas.find(meta => meta.id === uiStore.menuSavedAnswerId);

    const canShowNewButton = uiStore.nodes.length > 0 || store.error === AnswerError.FAILED_TO_FETCH_ANSWER;

    const shouldShowReadOnly = uiStore.shouldShowReadOnly;

    return (
      <Provider value={{
        askParams: uiStore.askParams,
        analysisFilters: uiStore.analysisFilters
      }}>
        <section className="answers">
          <OnboardingBeacon className="ob-answers-delayed-survey" delay={3000} />
          {haveAccessToAnswers && <SavedAnswers
            canShowNewButton={canShowNewButton}
            isLoading={store.isFetchingAnswers}
            hasError={store.error === AnswerError.UNKNOWN}
            savedAnswerId={params.savedAnswerId}
            metadata={store.savedAnswerMetas}
            onRetry={() => {
              store.fetchAnswers();
            }}
            onNewQuestion={() => {
              uiStore.reset();
              this.navigateTo();
            }}
            onSelect={(id: string) => {
              this.navigateTo(id);
            }}
            onDelete={(id: string) => {
              uiStore.setMenuSavedAnswerId(id);
              uiStore.setCurrentModal(AnswerModals.Delete);
            }}
            onShare={(id: string) => {
              uiStore.setMenuSavedAnswerId(id);
              uiStore.setCurrentModal(AnswerModals.Share);
            }}
            onDownload={() => {
              this.downloadAnswer();
            }}
          />}
          <DeleteModal
            title={activeMenuMeta?.title ?? ''}
            isVisible={uiStore.currentModal === AnswerModals.Delete}
            isDeleting={store.isDeletingAnswer}
            onCancel={() => {
              uiStore.setCurrentModal(AnswerModals.None);
              uiStore.setMenuSavedAnswerId(null);
            }}
            onConfirm={() => {
              this.deleteAnswer();
            }}
            onUnmount={() => this.focusNextSavedAnswer()}
          />
          <SharingModal
            isVisible={uiStore.currentModal === AnswerModals.Share}
            onClose={() => {
              uiStore.setCurrentModal(AnswerModals.None);
              uiStore.setMenuSavedAnswerId(null);
            }}
            orgName={uiStore.currentOrgName}
            currentSharingLink={uiStore.currentSharingLink}
            isSharingAllowed={uiStore.isSharingAllowed}
            isUpdatingIsSharingAllowed={uiStore.isUpdatingIsSharingAllowed}
            toggleSharing={() => this.toggleSharing()}
            onUnmount={() => this.focusNextSavedAnswer()}
            onOpenEmailModal={() => {
              uiStore.setCurrentModal(AnswerModals.Email);
              if (!store.emailPreviewHtml) {
                this.fetchEmailPreview();
              }
            }}
          />
          {uiStore.currentModal === AnswerModals.Email &&
            <EmailModal
              answerTitle={activeMenuMeta?.title ?? ''}
              onRetry={() => this.fetchEmailPreview()}
              emailPreviewHtml={store.emailPreviewHtml}
              emailPreviewError={store.emailPreviewError}
              isFetchingEmailPreview={store.isFetchingEmailPreview}
              existingEmailModalState={uiStore.answersEmailModalState}
              onUpdateEmailState={(state) => uiStore.updateAnswersEmailModalState(state)}
              sendEmailError={store.sendEmailError}
              isSendingEmail={store.isSendingEmail}
              onBack={() => {
                store.resetEmailRequestState();
                uiStore.setCurrentModal(AnswerModals.Share);
              }}
              onClose={() => {
                uiStore.setCurrentModal(AnswerModals.None);
                uiStore.setMenuSavedAnswerId(null);
                store.resetEmailRequestState();
              }}
              onCancel={() => {
                uiStore.setCurrentModal(AnswerModals.None);
                uiStore.setMenuSavedAnswerId(null);
                // clear modal state onCancel and not onClose
                uiStore.updateAnswersEmailModalState(
                  {
                    subject: null,
                    emails: []
                  }
                )
              }}
              onSendEmail={(emails, emailSubject, emailBody) => this.handleSendEmail(emails, emailSubject, emailBody)}
            />
          }
          {uiStore.currentModal === AnswerModals.Finalize &&
            <FinalizeEmailModal
              emails={uiStore.answersEmailModalState.emails}
              onClose={() => {
                uiStore.setCurrentModal(AnswerModals.None);
                uiStore.setMenuSavedAnswerId(null);
                store.resetEmailRequestState();
                uiStore.updateAnswersEmailModalState(
                  {
                    subject: null,
                    emails: []
                  }
                )
              }}
            />
          }
          <main>
            {this.getContent()}
            {!shouldShowReadOnly && <AskForm
              placeholder={placeholder}
              askParams={uiStore.askParams}
              dataSets={uiStore.dataSets}
              datasetPickerItems={uiStore.datasetPickerItems}
              onChange={value => uiStore.updateAsk(value)}
              onSubmit={() => this.onFormSubmit()}
            />}
          </main>
        </section>
      </Provider>
    );
  }

}

export const Answers = withHocs(AnswersClass);
